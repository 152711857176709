<template>
    <div class="wCaption overSiteIMG1">
        <agile :options="AgileOptions" :initialSlide=0 @after-change="e => currentSlide = e.currentSlide">
            <template slot="caption">{{ captions[currentSlide] }}</template>
            <div class="slide"><v-lazy-image  style="padding-left: 0px; padding-right: 0px;" src="images/over/site/histo1925GUMMbouwplan-01.jpg" src-placeholder="images/over/site/placeholderBouwplan.png"/></div>
            <div class="slide"><v-lazy-image  style="padding-left: 0px; padding-right: 0px;" src="images/over/site/histo1925GUMMbouwplan-03.jpg" src-placeholder="images/over/site/placeholderBouwplan.png"/></div>
            <div class="slide"><v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/over/site/histo1925GUMMbouwplan-02.jpg" src-placeholder="images/over/site/placeholderBouwplan.png"/></div>
            <template slot="prevButton">←</template>
            <template slot="nextButton">→</template>        
        </agile>        
        <div class="solo"><v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/over/site/histo1925GUMMbouwplan-01.jpg" src-placeholder="images/over/site/placeholderBouwplan.png"/><figcaption>1925, bouwplan zijaanzicht hoekhuis en meisjesschool.</figcaption></div>   
    </div>    
</template>

<script>  
    import { VueAgile } from 'vue-agile'    
    export default {    
            data: function () {
                "use strict";
                return {
                    AgileOptions: {
                                slidesToShow: 1,
                                navButtons: false,         
                                responsive: [
                                    {
                                        breakpoint: 768,
                                        settings: {
                                            navButtons: true,
                                        }
                                    }
                                ]                    
                      },
                    currentSlide: 0,
                    captions: [
                        '1925, bouwplan zijaanzicht hoekhuis en meisjesschool.',
                        '1925, bouwplan detail: hoekige keperbogen.',
                        '1925, bouwplan detail: buikige deelzuiltjes.'
                    ]
                };          
            },
            components: {
                'agile': VueAgile,
              },         
            methods: {
                lFilter: function(e) {
                    this.$parent.mfilter(e);         
                    window.scrollTo(0,0); 
                }

            }
        }
</script>

<style>
     @media only screen and (min-width: 0px) {
        
        .overSiteMG1{
            margin-bottom: 0vh;
        }       
        
    }
    
     @media only screen and (min-width: 1024px) {
        
        .overSiteIMG1{
            padding-right: 1vw;
        }       
        
    }
      

    @media only screen and (min-width: 1824px) {

        .overSiteIMG1{
            margin-bottom: 3vh;
            padding-right: 0vw;
        }

    }  

</style>