<template>
    <div>
        <div class="imgGummieLink">    
            <a href="https://www.instagram.com/reddreamhead/" target="_blank" rel="noopener noreferrer"><v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/over/huis/gummKatrien.png" src-placeholder="images/over/huis/placeHolderHuis.png"/></a>
        </div>         
        <p class="txtGummie"  style="margin-top: 1vh;padding-left: 3vw; padding-right: 5vw;"><em>Mijn gelukjes: blauwe lucht - de hele dag gewoon alleen een goed boek lezen - toevallige kleurencombinaties - een restaurant waar ik niet moet kiezen - een wei vol bloemen - picknicken met uitzicht na een lange hike - een spelletje winnen - dieren in het wild tegenkomen - een kat die lekker bij mij in de zetel komt liggen - zotte Stijn.</em> --Katrien</p>
    </div>     
</template>

<script>
export default {
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

    
    @media only screen and (min-width: 0px) {
     

    }    
    
    @media only screen and (min-width: 1824px) {

    }    
    
</style>
