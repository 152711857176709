<template>
    <div class="wCaption overSiteIMG2"> 
        <agile :options="AgileOptions" :initialSlide=0 @after-change="e => currentSlide = e.currentSlide">
            <template slot="caption">{{ captions[currentSlide] }}</template>
            <div class="slide"><v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/over/site/histo1974GUMMmeisjesschool-01.jpg" src-placeholder="images/over/site/placeholderHistoSite.png"/></div>
            <div class="slide"><v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/over/site/histo2020GUMMmeisjesschool-01.jpg" src-placeholder="images/over/site/placeholderHistoSite.png"/></div>
            <div class="slide"><v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/over/site/histo1992GUMMhuis-01.jpg" src-placeholder="images/over/site/placeholderHistoSite.png"/></div>
            <div class="slide"><v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/over/site/histo2020GUMMhuis-01.jpg" src-placeholder="images/over/site/placeholderHistoSite.png"/></div>            
            <div class="slide"><v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/over/site/histo1994GUMMjongensschool-01.jpg" src-placeholder="images/over/site/placeholderHistoSite.png"/></div>  
            <div class="slide"><v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/over/site/histo2020GUMMjongensschool-02.jpg" src-placeholder="images/over/site/placeholderHistoSite.png"/></div>
            <template slot="prevButton">←</template>
            <template slot="nextButton">→</template>  
        </agile>
        <div class="solo"><v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/over/site/histo1992GUMMhuis-01.jpg" src-placeholder="images/over/site/placeholderHistoSite.png"/><figcaption>1992, © Agentschap Onroerend Erfgoed</figcaption></div>
    </div>    
</template>

<script>
    import { VueAgile } from 'vue-agile'
    export default {

        data: function () {
            "use strict";
            return {
                AgileOptions: {
                            slidesToShow: 1,
                            navButtons: false,         
                            responsive: [
                                {
                                    breakpoint: 768,
                                    settings: {
                                        navButtons: true,
                                    }
                                }
                            ]                    
                  },
                currentSlide: 0,
                captions: [
                    '1974, © Regionale Beeldbank Mechelen',
                    '2020',
                    '1992, © Agentschap Onroerend Erfgoed',
                    '2020',
                    '1994, © Agentschap Onroerend Erfgoed',
                    '2020'
                ]                
            };          
        },
        components: {
            'agile': VueAgile,
          }, 
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>    
     @media only screen and (min-width: 0px) {
        
        .overSiteIMG2{
            margin-top: 0vh;
            margin-right: 0vw;
            padding-left: 0vw;
        }       
        
    }
      

    @media only screen and (min-width: 1824px) {

        .overSiteIMG2{
            margin-top: 10vh;
            margin-right: 1vw;
            padding-left: 5vw;
            margin-bottom: 8vh;
        }

    }  
    
</style>