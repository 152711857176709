<template>
    <div>
        <p class="title">leesvoer</p>    
        <p>
            <ul>
                <li>
                    <div>Boeken: 
                        <p>             
                        <ul><li><div><a href="https://ruby-press.com/shop/together-the-new-architecture-of-the-collective/" target="_blank" rel="noopener noreferrer">Together! The New Achitecture of the collective</a></div></li></ul>
                        <ul><li><div><a href="https://newsociety.com/books/c/creating-cohousing" target="_blank" rel="noopener noreferrer">Creating Cohousing: Building Sustainable Communities</a></div></li></ul>
                        <ul><li><div><a href="http://www.cohousingco.com/products/happily-ever-aftering-in-cohousing-a-handbook-for-community-living" target="_blank" rel="noopener noreferrer">Happily ever aftering in cohousing</a></div></li></ul></p>                        
                    </div>
                </li>
                <br>
                <li>
                  <div>Artikels & Reportages:
                        <p><ul><li><div><a href="https://klimaatneutraal.mechelen.be/paraat-voor-het-klimaat-winnaars-2020" target="_blank" rel="noopener noreferrer">Paraat voor het klimaat: Winnaars 2020</a></div></li></ul>
                         <ul><li><div><a href="https://klimaatneutraal.mechelen.be/een-rijk-verleden-en-een-duurzame-toekomst-met-gumm-co-housing" target="_blank" rel="noopener noreferrer">Een rijk verleden en een duurzame toekomst met...</a></div></li></ul>
                         <ul><li><div><a href="https://www.rtv.be/artikels/vlindervinders-en-mottenzotten-a70728" target="_blank" rel="noopener noreferrer">Vlindervinders en Mottenzotten, 2019</a></div></li></ul></p>        
          

                  </div>
                </li>

            </ul>
        </p>
    </div>     
</template>

<script>  
    export default {    
            methods: {
                lFilter: function(e) {
                    this.$parent.mfilter(e);         
                    window.scrollTo(0,0); 
                }

            }
        }
</script>

<style></style>