<template>
    <div>
        <p class="title">links</p>    
        <p>
            <ul>
                <li><div><a href="http://www.samenhuizen.be/" target="_blank" rel="noopener noreferrer">Samenhuizen vzw</a>: <p>Samenhuizen vzw wil de wooncultuur verruimen naar meer duurzame woonopties. Ze is het forum voor alles wat gemeenschappelijk wonen (waaronder cohousing) aangaat en stimuleert de realisatie van meer gemeenschappelijk wonen. Hier vind je een bron van informatie en hulp om van start te gaan.</p></div></li>
                <li>
                    <div>Andere cohousing projecten in Vlaanderen en daarbuiten: 
                        <p><ul><li><div><a href="https://www.okelaar.be/" target="_blank" rel="noopener noreferrer">De Okelaar, Meise</a></div></li></ul>
                        <ul><li><div><a href="http://eikenberg.be/" target="_blank" rel="noopener noreferrer">Eikenberg, Brugge</a></div></li></ul>
                        <ul><li><div><a href="https://www.cohousingwaasland.be/" target="_blank" rel="noopener noreferrer">Waasland, Sint Niklaas</a></div></li></ul>
                        <ul><li><div><a href="https://www.facebook.com/cohousingwijgenco/" target="_blank" rel="noopener noreferrer">Wijg&Co, Wijgmaal</a></div></li></ul>
                        <ul><li><div><a href="https://www.facebook.com/Cohousingdesijs/" target="_blank" rel="noopener noreferrer">De Sijs, Heverlee</a></div></li></ul></p>
                    </div>
                </li>
                <li><div><a href="http://www.mechelen.be/" target="_blank" rel="noopener noreferrer">Stad Mechelen</a></div></li> 
                <li><div><a href="https://klimaatneutraal.mechelen.be" target="_blank" rel="noopener noreferrer">Mechelen Klimaat Neutraal</a></div></li>  
                <li><div><a href="https://www.natuurpunt.be/pagina/mechelen-nature" target="_blank" rel="noopener noreferrer">Mechelen For Nature</a></div></li>  
                <li><div><a href="https://www.vlaanderenbreektuit.be" target="_blank" rel="noopener noreferrer">Vlaanderen Breek Uit</a></div></li> 
                <li><div><a href="https://www.denc-studio.be" target="_blank" rel="noopener noreferrer">DENC!-STUDIO</a> &ndash; <a href="https://g-build.be/" target="_blank" rel="noopener noreferrer">G-build</a> &ndash; <a href="http://www.pxp.be/" target="_blank" rel="noopener noreferrer">PxP</a></div></li> 
                              
            </ul>
        </p>
    </div>     
</template>

<script>  
    export default {    
            methods: {
                lFilter: function(e) {
                    this.$parent.mfilter(e);         
                    window.scrollTo(0,0); 
                }

            }
        }
</script>

<style></style>
