<template>
    <div class="overZaalTXT">
        <p class="title">over de gummzaal</p>
        <p class='p1' lang="nl">Het oude kerkgebouw maakt een belangrijk deel uit van de Gumm site. Het was er al bij in 1925 samen met de meisjesschool en het gummhuis. In dat jaar werd op 15 augustus dan ook officieel de eerste steen gelegd. In 1926 al, enkele maanden na de meisjesschool, werd het gebouw in gebruik genomen als een plek voor bezinning in de Caputsteenbuurt. De daarop volgende decennia bleef het deze functie vervullen, tot in 2014.</p>               
        <p class='p1' lang="nl">Het kerkgebouw bestaat uit een rechthoekig schip met 5 traveeën aangevuld met een inkom- en koortravee. Ook dit gebouw is dus art-deco getint: een keperboogvormig portaal, een gevelbekleding van rode klampsteen afgewisseld met gecementeerde gevelvlakken en een hoge plint van kiezelbeton. Die laatste is doorgetrokken op de gevel van het gummhuis en de meisjesschool. Het interieur wordt gedomineerd door gedrukte spits- en keperbogen op verschillende onderdelen van de kerk. Het zijn de 3 spitsbogen boven het oksaal, die zich uiteindelijk een weg vonden in ons logo.</p>   
        <p class='p1' lang="nl">Verscheiden investeringen in het gebouw zijn al gepland, maar er zullen er nog nodig zijn om het gebouw een volwaardige nieuwe invulling te geven: de <span class= "linkInText"  @click="lFilter(8)">gummzaal</span>. We gaan voor een dynamische, creatieve en buurtgerichte invulling, en staan zeker open voor nieuwe ideeën en initiatieven. Momenteel gaat onze aandacht op aan de verbouwing van de wooneenheden, het gummhuis en de gummtuin. Maar de gummzaal wordt sowieso een boeiende plek om deel van uit te maken.</p>         
    </div>     
</template>

<script>  
    export default {    
            methods: {
                lFilter: function(e) {
                    this.$parent.mfilter(e);         
                    window.scrollTo(0,0); 
                }

            }
        }
</script>

<style>

    
    @media only screen and (min-width: 0px) {
        
        .overZaalTXT{       
        }       
        
    }

    @media only screen and (min-width: 1024px) {
        
        .overZaalTXT{
            margin-top: -2vh;       
        }       
        
    }


    @media only screen and (min-width: 1824px) {

        .overZaalTXT{
            margin-top: -0.25vh;      
        }

    } 
    
</style>