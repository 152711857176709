<template>
    <div class="overHuisIMG1">
        <div class="imgGummieLink">    
            <a href="https://shoppenin.mechelen.be/blog-marisol" target="_blank" rel="noopener noreferrer"><v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/over/huis/gummMarc.png" src-placeholder="images/over/huis/placeHolderHuis.png"/></a>
        </div>          
        <p class='txtGummie' style="margin-top: 1vh;padding-left: 5vw; padding-right: 1vw;"><em>Authentieke paella is mijn favoriete gerecht. Ik doe daar echt van alles in en volg niet echt een recept: chorizo, kippenbouten, venusschelpen, mosselen, scampi’s en gamba’s, garnalen en wel 5 soorten vis, veel groenten (paprika, ajuin, look, wortel, erwten…) en de goede rijst: Arroz bomba. Kokerellen: Ik doe het graag! Meestal wat improviseren en uitproberen. Voor op mijn buitenterras van unit 11 heb ik vorig jaar daarvoor speciaal een buitenkeuken gemaakt - die kan buiten blijven staan in weer en wind. Als het goed weer is kook ik dus buiten en dan moeten degenen die honger hebben maar afkomen.</em> --Marc</p>           
    </div>     
</template>

<script>
export default {
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

    
    @media only screen and (min-width: 0px) {
     

    }    
    
    @media only screen and (min-width: 1824px) {
        .overHuisIMG1{
            padding-bottom: 1vh;
        }
    }    
    
</style>
