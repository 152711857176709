<template>
    <div class = "zaalIMG1">    
        <v-lazy-image style="padding-left: 0vw; padding-right: 1vw;" src="images/zaal/werfGUMM102020www-02.jpg" src-placeholder="images/zaal/placeholderZaalBig.png"/>
    </div>    
</template>

<script>  
    export default {    
            methods: {
                lFilter: function(e) {
                    this.$parent.mfilter(e);         
                    window.scrollTo(0,0); 
                }

            }
        }
</script>

<style> 
    @media only screen and (max-width: 0px) {
        
        .zaalIMG1{
          
        }        
        
    }
    
    @media only screen and (min-width: 1024px) {

        .zaalIMG1{
            padding-left: 7vw;
            padding-right: 4vw;
            margin-top: 4vh;
        }     
        


    }   

    @media only screen and (min-width: 1824px) {

        .zaalIMG1{
            padding-left: 5vw;
            padding-right: 2vw;
            margin-top: 4vh;
        }

    }
</style>