<template>
    <div class="overGummTXT">
        <p class="title">gumm cohousing</p>
        <p class='p1' lang="nl">Een cohousing project in Mechelen dat een thuis biedt aan bewoners van 24 units en een pastorijwoning. Begin 2020 zijn de werken gestart om hier een gedeelde plek van te maken voor jong en oud. Waarden zoals ecologie, architectuur en samenwonen staan centraal. Gumm is echter meer dan cohousing alleen! Er is ook het oude kerkgebouw dat we omvormen tot de <span class= "linkInText" @click="lFilter(8)">gummzaal</span>. We verbouwen niet alleen onze gebouwen, ook de oude speelplaats en pastorietuin transformeren we tot een groene oase: de <span class= "linkInText" @click="lFilter(9)">gummtuin</span>.</p>
    </div>    
</template>

<script>
export default {
        name: 'gummTXT',
        methods: {
            lFilter: function(e) {
                this.$parent.mfilter(e);
                window.scrollTo(0,0); 
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    
    @media only screen and (min-width: 1024px) {

        .overGummTXT{
            margin-bottom: 0vh;
        }

    } 
      

    @media only screen and (min-width: 1824px) {

        .overGummTXT{
            margin-bottom: 5vh;
        }

    }  
</style>

