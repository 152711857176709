<template>
    <div class="overGummIMG">
        <v-lazy-image style="" src="images/over/gummHoekDromenMag.jpg" src-placeholder="images/over/placeholderHoekDromenMag.png"/>
    </div>
</template>

<script>
    export default {    
            name: 'overGummIMG.vue',
            methods: {
                lFilter: function(e) {
                    this.$parent.mfilter(e);         
                    window.scrollTo(0,0); 
                }

            }
        }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>    

     @media only screen and (min-width: 0px) {
        
        .overGummIMG{
        }       
        
    }
    
    @media only screen and (min-width: 1024px) {

        .overGummIMG{
            padding-right: 10vw;
            padding-left: 3vw;
            margin-bottom: 5vh;
        }

    } 
      

    @media only screen and (min-width: 1824px) {

        .overGummIMG{
            padding-right: 3vw;
        }

    }    

</style>

