<template>
  <div id="app" class="main">
			<div class="loader" id="loader">
				laden
				<div id="circle"></div>
			</div>
            <div class="mainbuttons" v-scroll-to="{ el: '#app', offset: -10  }">
                <transition name="imgFade"><div class="mainButton" v-bind:style="{'background-image': 'url(' + logoIMG + ')'}" v-bind:key="logoIMG" @click="reset()"></div></transition>         
                <div class="mainBar"></div>    
               <div class="button top o">
                        <dropdown-menu :overlay=false mode="hover"  class="custom-style" transition="opacity">
                            <div slot="trigger"><span :class="[filterM === 'gumm' ? 'is-checked' : '']" v-scroll-to="{ el: '#app', offset: -10 }" @click="mfilter(1)">over gumm <font face="Roboto">→</font></span></div>
                            <div class="dropdownlistO" slot="body">
                                <div class="listitem"><span :class="[filterM === 'overSite' ? 'is-checked' : '']" v-scroll-to="{ el: '#app', offset: -10 }" @click="mfilter(2)">de site</span></div>
                                <div class="listitem"><span :class="[filterM === 'overBouw' ? 'is-checked' : '']" v-scroll-to="{ el: '#app', offset: -10 }" @click="mfilter(3)">de verbouwing</span></div>
                                <div class="listitem"><span :class="[filterM === 'overZaal' ? 'is-checked' : '']" v-scroll-to="{ el: '#app', offset: -10 }" @click="mfilter(4)">de gummzaal</span></div>
                                <div class="listitem"><span :class="[filterM === 'overHuis' ? 'is-checked' : '']" v-scroll-to="{ el: '#app', offset: -10 }" @click="mfilter(5)">het gummhuis</span></div>
                                <div class="listitem"><span :class="[filterM === 'overCH' ? 'is-checked' : '']" v-scroll-to="{ el: '#app', offset: -10 }" @click="mfilter(6)">cohousing</span></div>
                                <div class="listitem"><span :class="[filterM === 'contact' ? 'is-checked' : '']" v-scroll-to="{ el: '#app', offset: -10 }" @click="mfilter(7)">contact</span></div>
                            </div>
                          
                        </dropdown-menu>                         
                </div>
                <div class="button side zaal left" :class="[filterM === 'zaal' ? 'is-checked' : '']" v-scroll-to="{ el: '#app', offset: -10 }"><span @click="mfilter(8)">gummzaal</span></div>                               
            </div>
            <div class="sidebuttons">

                <div class="button side tuin left" :class="[filterM === 'tuin' ? 'is-checked' : '']" v-scroll-to="{ el: '#app', offset: -10 }"><span @click="mfilter(9)"><font face="Roboto">←</font> gummtuin</span></div>
                <!--<div class="button side kal left" :class="[filterM === 'kalender' ? 'is-checked' : '']" v-scroll-to="{ el: '#app', offset: -10 }"><span @click="mfilter(10)"><font face="Roboto">←</font> gummkalender</span></div>      -->
            </div>      
            <div class="content-grid">
                <isotope ref="cpt" id="isotope" :item-selector="'grid-item'" :list="list" :options='getOptions()' @filter="filterOption=arguments[0]" @sort="sortOption=arguments[0]" v-images-loaded.on.progress="layout">
                    <div v-for="item,index in list" :key="index" :class="item.colWdth">
                         <div class="grid-sizer"></div>
                         <p class="content"><v-runtime-template :template="item.content" :parent="parentComponent"></v-runtime-template></p> 
                    </div>
                </isotope>
            </div>   			
		</div>
</template>

<script>
    import isotope from 'vueisotope';
    import imagesLoaded from 'vue-images-loaded';
    import VueScrollTo from 'vue-scrollto';
    import VRuntimeTemplate from "v-runtime-template";

    export default {
        name: 'App',
        components: {
            isotope,
            imagesLoaded, 
            VRuntimeTemplate,
        },    
        data: function () {
            "use strict";
            return {
                parentComponent: this,                
                list: [
                    {colWdth: "threecol", order: 0, m: "-1", content: "<p class='p1'></p>"},  
                    {colWdth: "twocol", order: 13, m: "1", content: "<overGummIMG/>"},
                    {colWdth: "", order: 9, m: "1", content: "<overGummTXT/>"},
                    {colWdth: "", order: 11, m: "7", content: "<infoContact/>"},
                    {colWdth: "", order: 10, m: "7", content: "<infoNws/>"}, 
                    {colWdth: "", order: 16, m: "2", content: "<overSiteIMG1/>"},
                    {colWdth: "", order: 17, m: "2", content: "<overSiteTXT/>"},
                    {colWdth: "threecol", order: 14, m: "1", content: "<dividerD/>"},                      
                    {colWdth: "twocol", order: 15, m: "2", content: "<overSiteIMG2/>"},
                    {colWdth: "threecol", order: 1, m: "3", content: "<overBouwIMG1/>"},
                    {colWdth: "", order: 19, m: "3", content: "<overBouwTXT/>"},
                    {colWdth: "twocol", order: 20, m: "3", content: "<overBouwIMG2/>"},
                    {colWdth: "twocol", order: 21, m: "3", content: "<overBouwIMG3/>"},
                    {colWdth: "twocol", order: 23, m: "4", content: "<overZaalIMG1/>"},  
                    {colWdth: "", order: 24, m: "4", content: "<overZaalIMG2/>"}, 
                    {colWdth: "", order: 23, m: "4", content: "<overZaalTXT/>"},   
                    {colWdth: "threecol", order: 25, m: "1", content: "<dividerF/>"},
                    {colWdth: "", order: 26, m: "6", content: "<overChTXT1/>"},    
                    {colWdth: "", order: 27, m: "6", content: "<overChTXT2/>"}, 
                    {colWdth: "", order: 28, m: "6", content: "<overChTXT3/>"}, 
                    {colWdth: "", order: 29, m: "6", content: "<overChTXT4/>"},
                    {colWdth: "threecol", order: 30, m: "1", content: "<dividerE/>"}, 
                    {colWdth: "twocol", order: 31, m: "5", content: "<overHuisTXT/>"},
                    {colWdth: "", order: 39, m: "5", content: "<overHuisIMG1/>"},
                    {colWdth: "", order: 33, m: "5", content: "<overHuisIMG2/>"},
                    {colWdth: "", order: 36, m: "5", content: "<overHuisIMG3/>"},
                    {colWdth: "", order: 34, m: "5", content: "<overHuisIMG4/>"},
                    {colWdth: "", order: 40, m: "5", content: "<overHuisIMG5/>"},
                    {colWdth: "", order: 38, m: "5", content: "<overHuisIMG6/>"},
                    {colWdth: "", order: 35, m: "5", content: "<overHuisIMG7/>"},
                    {colWdth: "", order: 32, m: "5", content: "<overHuisIMG8/>"},
                    {colWdth: "", order: 41, m: "5", content: "<overHuisIMG9/>"},
                    {colWdth: "", order: 37, m: "5", content: "<overHuisIMG10>"},                    
                    {colWdth: "threecol", order: 50, m: "1", content: "<dividerB/>"},
                    {colWdth: "", order: 52, m: "8", content: "<zaalTXT/>"},
                    {colWdth: "twocol", order: 53, m: "8", content: "<zaalIMG1/>"},
                    {colWdth: "twocol", order: 51, m: "8", content: "<zaalIMG2/>"},
                    {colWdth: "threecol", order: 54, m: "1", content: "<dividerC/>"},
                    {colWdth: "", order: 62, m: "9", content: "<tuinTXT1/>"},
                    {colWdth: "twocol", order: 61, m: "9", content: "<tuinIMG1/>"},
                    {colWdth: "", order: 65, m: "9", content: "<tuinTXT2/>"},
                    {colWdth: "", order: 64, m: "9", content: "<tuinIMG2/>"},   
                    {colWdth: "twocol", order: 63, m: "9", content: "<tuinIMG4/>"},                 
                    {colWdth: "twocol", order: 66, m: "9", content: "<tuinIMG3/>"},                
                    {colWdth: "threecol", order: 66, m: "9", content: "<tuinIMG5/>"},     
                    {colWdth: "", order: 67, m: "9", content: "<tuinTXT3/>"},
                    {colWdth: "", order: 68, m: "9", content: "<tuinIMG6/>"}, 
                    {colWdth: "threecol", order: 69, m: "9", content: "<p class='p1'></p>"},
                    {colWdth: "twocol", order: 70, m: "9", content: "<tuinIMG7/>"},                     
                    {colWdth: "", order: 71, m: "9", content: "<tuinTXT4/>"},                    
                    //{colWdth: "threecol", order: 60, m: "10", content: "<dividerC/>"},
                    //{colWdth: "threecol", order: 61, m: "10", content: "<gglCalendar/>"},          
                    {colWdth: "threecol", order: 99, m: "99", content: "<dividerF/>"},
                    {colWdth: "", order: 10, m: "101", content: "<infoIMG/>"}
                ],
                colWitdh: "",
                filterM: "all",
                optionsM: ["gumm", "gumm", "gumm", "gumm", "gumm", "gumm", "gumm", "zaal", "tuin", "kalender"],
                logosM: ["images/logos/GUMM-groen.svg", "images/logos/GUMM-groen.svg", "images/logos/GUMM-groen.svg", "images/logos/GUMM-groen.svg", "images/logos/GUMM-groen.svg", "images/logos/GUMM-groen.svg", "images/logos/GUMM-groen.svg", "images/logos/GUMM-zaal.svg", "images/logos/GUMM-tuin.svg", "images/logos/GUMM-groen.svg"],
                logoIMG: "images/logos/GUMM-groen.svg",
                m: [-1],
                windowWidth: window.outerWidth,
            };
        },
        methods: {
            getOptions: function() {
                "use strict";
                return {
                    masonry: {
                        columnWidth: '.grid-sizer'
                    },
                    itemSelector: ".grid-item",
                    transitionDuration: '1s',
                    getFilterData: {
                        "filter": (el) => {	
                                if (this.m == -1){
                                    var mBool = (el.m <= 100)
                                } else if (this.m == 1){
                                    mBool = (el.m >= 1 && el.m <= 7 || el.m == -1);
                                } else if (this.m > 1 && this.m <= 6){
                                    mBool = (el.m == this.m || el.m == 99 || el.m == -1);
                                } else if (this.m == 7){
                                    mBool = (el.m == this.m || el.m == 101 || el.m == 99 || el.m == -1);
                                } else if (this.m > 7  ){
                                    mBool = (el.m == this.m || el.m == 99 || el.m == -1);
                                } 
                                return mBool;
                        }
                    },
                    getSortData: {
                        order: 'order'
                    },
                    sortBy: 'order'                
                }
            },	
            layout: function() {
                this.$refs.cpt.layout('masonry');
                this.$refs.cpt.filter("filter");
                document.getElementById("loader").style.visibility = "hidden";	
                document.body.style.overflow = "auto";	
            },
            mfilter: function(nm) {
                if (this.m != nm){
                        this.m = nm;
                        if (nm != -1){
                            this.filterM = this.optionsM[nm-1];
                            this.logoIMG = this.logosM[nm-1];  
                        } else if (nm == -1){
                            this.filterM = 'all';
                            this.logoIMG = "images/logos/GUMM-groen.svg";
                        }
                        this.$refs.cpt.filter("filter");
                    }     
            }, 
            reset: function() {	
                this.m = -1;
                this.filterM = 'all';
                this.logoIMG = "images/logos/GUMM-groen.svg";
                this.$refs.cpt.filter("filter");
            },
            refresh: function(){
                if (this.windowWidth != window.outerWidth){
                    location.reload();
                }
                this.Windowwith = window.outerWidth
            },      
        },
        directives: {
            imagesLoaded,VueScrollTo
        }, 
        created() {
          window.addEventListener("resize", this.refresh);
        },
        destroyed() {
          window.removeEventListener("resize", this.refresh);
        },
    }
</script>

<style>
    * {
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
    }

    

    body {
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
        font-weight: 300;
        background-color: #f1f2eb;
        z-index: 0;
        color: #333;
        max-width: 100vw;
        overflow-x: hidden;
        margin-left: 0vw;
        overflow: hidden;
        height: 100%
    }

    @-webkit-keyframes spin {
      0% { -webkit-transform: rotate(0deg); }
      100% { -webkit-transform: rotate(360deg); }
    }

    @keyframes spin {
      0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
      100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
    }

    #loader{
        visibility: visible;
    }

    #circle {
        margin-left: -30px;
        margin-top: 13.3333px;
        left: 50%;
        -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
                transform: translateX(-50%);
        position: relative;
        z-index: 1;
        width: 62px;
        height: 62px;
        border: 5px solid #ccc;
        border-radius: 50%;
        border-top: 5px solid #999;
        -webkit-animation: spin 1s linear infinite;
        animation: spin 1s linear infinite;
    }

    .loader{
        display: block;
        position: absolute;
        background-color: #f1f2eb;
        padding-top: 13.3333px;
        top: 0px; 
        bottom: -10px;
        width: 100%;
        opacity: 1;
        z-index: 999;
        margin: 0 auto;
        left: 50%;
        -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
                transform: translateX(-50%);
        font-family: 'Roboto', sans-serif;
        text-align: center;
    }

    .main{
        z-index: 0;
        margin: 0 auto;
    }

    /* prefix with transition name */
    .fade-enter-active {
      opacity: 1;
    }

    .fade-leave-active {
      opacity: 1;
    }

    .fade-enter,
    .fade-leave-to {
      opacity: 0;
    }

    .imgFade-enter-active {	
      z-index:120;
      opacity: 1;
    }

    .imgFade-leave-active {
      opacity: 1;
    }

    .imgFade-enter,
    .imgFade-leave-to {
        z-index: 110;
        opacity: 0;
    }

    /* ---- buttons ---- */

    .sidebuttons{
        position: fixed;   
        z-index: 2;
        display: -ms-grid;
        display: grid;
        width: 100vw;
        top: 200px;
        -ms-grid-columns: 5vw 12.5vw 2.5vw 20vw 20vw 20vw 2.5vw 12.5vw 5vw;
        grid-template-columns: 5vw 12.5vw 2.5vw 20vw 20vw 20vw 2.5vw 12.5vw 5vw;
        grid-template-rows: repeat(5,17vh) 0.5vh ;
        grid-template-areas:
            ". t . . . . . . ."
            ". . . . . . . . ."
            ". k . . . . . . ."
            ". . . . . . . . ."
            ". . . . . . . . .";      
    }

    .mainbuttons{
        position: fixed;   
        z-index: 4;
        display: -ms-grid;
        display: grid;
        width: 100vw; 
        height: 0px;
        -ms-grid-columns: 5vw 10vw 20vw 5vw 20vw 5vw 20vw 10vw 5vw;
        grid-template-columns: 5vw 10vw 20vw 5vw 20vw 5vw 20vw 10vw 5vw;
        grid-template-rows: 2.5vh;
        grid-template-areas:
            ". . . . . . . . ."
            ". z . . m . . o .";      
    }
    
    /*
    
    .line1, .line2{
        display: block;
        height: 1px; 
        margin-top: 15px;
        background-color: #01514a;    
    }
    
    .line1{
        grid-area: l1;
        margin-left: -1vw;
        margin-right: -5vw;        
    }
    
    .line2{
        grid-area: l2;
        margin-left: -5.5vw;
        margin-right: -2.5vw;         
    }   
    
    .line3, .line4{
        display: block;
        transform: rotate(90deg);
        transform-origin: 0%;
        height: 1px; 
        margin-left: 8px;
        background-color: #01514a;      
    }    
    
    .line3{
        grid-area: l3;
        margin-top: -11vh;
        margin-right: -12vh;

    }
    */

    .mainBar{
        display: block;
        grid-area: m;
        justify-self: center;    
        height: 150px; 
        margin-top: -2.25vh;
        padding: 0vh 50vw 0vh 50vw; 
        z-index: 9;
        background-color:rgba(241, 242, 235, 0.9);   
    }

    
    .mainButton{
        display: block;
        grid-area: m;
        cursor: pointer;  
        justify-self: center;    
        height: 150px;
        width: 150px;  
        margin-top: -2.25vh;
        padding: 0vh 0vw 0vh 0vw;
        background-repeat: no-repeat;
        background-size: 150px auto;    
        background-position:50% 50%;
        -webkit-transition: all 0.8s ease-in-out;
        -o-transition: all 0.8s ease-in-out;
        transition: all 0.8s ease-in-out;   
        z-index: 11; 
    }

    .button {
        border: none;
        color: #333;
        font-size: calc(11px + 0.5vw);
        width: 220px;
        height: 220px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        z-index:10;                                           
    }

    .button span:hover{
        font-style: italic;
        color: #01514a;
        cursor: pointer;
    }

    .button.is-checked,
    .button span.is-checked{
        color: #01514a;
        font-style: italic;
    }
    
    .v-dropdown-menu.custom-style .v-dropdown-menu__container {
        border: 0;
        background-color: transparent;
    }
    .v-dropdown-menu.custom-style{
        width: inherit;
    }
    .dropdownlistO{
        padding-right: 0.75vw;
        font-size: calc(12.5px + 0.2vw);
    }

    .v-dropdown-menu.custom-style .opacity-enter-active {
      transition: opacity 0.6s;
    }
    .v-dropdown-menu.custom-style .opacity-enter, .v-dropdown-menu.custom-style .opacity-leave-to {
      opacity: 0;
    }
    .v-dropdown-menu.custom-style.v-dropdown-menu--mode-hover .opacity-enter, .v-dropdown-menu.custom-style.v-dropdown-menu--mode-hover .opacity-leave-to {
      transition-delay: 0.3s;
    }
    
    .o {
        grid-area: o;
        justify-self: right; 
        text-align: right;
        height: 220px;         
    }

    .tuin{
        grid-area: t; 
        justify-self: left; 
        text-align: left;    
        transform: rotate(-90deg); 
        margin-left: -0.5vh;   

    }

    .zaal{
        grid-area: z;
        justify-self: left;   
       
    }

    .kal{
        grid-area: k;
        justify-self: left; 
        text-align: left;
        transform: rotate(-90deg);   
        margin-left: -0.5vh;  
    }

    /* ---- isotope ---- */

    .content-grid {
        margin: 0 auto;
        z-index: 3;    
        max-width: 68vw;     
    } 

    #isotope{ 
      margin: 0 auto;
      top: calc(150px + 1vh);
      background-color: transparent;
      z-index: 3;
    }
    /* clear fix */
    .grid:after {
      content: '';
      display: block;
      clear: both;
    }
    .isoDefault {
      min-height: 210px;
    }

    /* ---- .grid-item ---- */
    .grid-item {
        z-index: 0;
        height: auto;     
    }

    .grid-item img {
      display: block;
      width: 100%;
    }

    .grid-item .content {
        text-transform: none;
        padding: 0 0.5vw 0 0.5vw;
        color: #333; 
        font-size: calc(12.5px + 0.2vw);
        overflow-wrap: break-word;
    }
    
    .grid-item p.p1{
        margin: -0.25em 0 1em 0;  
        overflow-wrap: break-word;
        word-wrap: break-word;   
        word-break: break-word;
        line-height: 1.5;
        /* Adds a hyphen where the word breaks, if supported (No Blink) */
        -ms-hyphens: manual;
        -moz-hyphens: manual;
        -webkit-hyphens: manual;
        hyphens: manual;   
        /*hyphenate-limit-chars: 6 3 3;
        hyphenate-limit-lines: 2;
        hyphenate-limit-last: always;
        hyphenate-limit-zone: 8%;*/
    }
    
    .grid-item a{
        color: #01514a;
        font-style: normal;
        cursor: pointer;
        text-decoration: underline;
        text-underline-position: under;
        -webkit-text-decoration-style: dotted;
                text-decoration-style: dotted;
    }  
    
    .grid-item a:hover{
        font-style: normal;
        color: #01514a;
        opacity: 1;
        text-decoration: none;
    }
    
    .grid-item p.title{
        font-size: calc(12.5px + 0.2vw);
        display: block;
        font-weight: 500;
        line-height: 1;
        padding: 0px;
        margin-bottom: 20px;
        margin-top: 0px;
        color: #01514a;
        font-family: 'Montserrat', sans-serif;
    }
    
    ul {
        list-style-type: "▶";
        list-style-position: outside;
        font-size: calc(5px + 0.2vw);
        color: #555;

    }

    li div {
        font-size: calc(12.5px + 0.2vw);  
        position: relative;
        top: 0.125vh;
        color: #333;
        margin-bottom: 5px;
        text-indent: 1vw;        
    }

    li div p {
        text-indent: 0vw;
        margin-left: 1vw;
    }

    span.emph {
        font-weight: 500; 
        color: #333;
    }  
    
    span.linkInText {
        color: #01514a;
        font-style: normal;
        cursor: pointer;
        display:inline-block;
        text-decoration: underline;
        text-underline-position: under;
        -webkit-text-decoration-style: dotted;
                text-decoration-style: dotted;
    }  
    
    span.linkInText:hover{
        font-style: normal;
        color: #01514a;
        opacity: 1;
        text-decoration: none;
    }
    
    .imgGummieLink .imgGummie{
        padding-left: 0.5vw; 
        padding-right: 0.5vw;        
    }
    
    .imgGummieLink img:hover {
        opacity: 0.9;
        transform: scale(0.99);
        -webkit-transition: .05s ease-in-out;
        -o-transition: .05s ease-in-out;
        transition: .05s ease-in-out;        
    }  
    
    .txtGummie{
        font-size: calc(10px + 0.2vw);
        line-height: 1.5;        
    }
    
    em {
      font-style: italic;
    }    

    h2 { 
        color: #01514a;
        font-style: italic;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        font-size: calc(10px + 1vw); 
    }     
     
 
    .v-lazy-image {
        opacity: 0.5;
        transition: opacity 2s;
    }
    .v-lazy-image-loaded {
        opacity: 1
        
    }    
    
    /* ---- responsiveness ---- */
    

    @media only screen and (min-width: 0px) {
        .grid-sizer,
        .grid-item,
        .grid-item.twocol,
        .grid-item.threecol {
            width: 100%; 
        }
    }
    
    @media only screen and (max-width: 768px) {           

        .mainButton{
            height: 110px;
            width: 110px;
            background-size: 110px auto;               
        }
        .mainBar{
            display: block;
            grid-area: m;
            justify-self: center;    
            height: 110px;
        }
        #isotope{
            top:120px;
        }

        .grid-item{
            min-width: 210px;
        } 
            
        .tuin{
            justify-self: left; 
            text-align: center;
            transform: rotate(-90deg); 
            margin-left: -0.5vh;   
        }
        .ch{
            transform: rotate(90deg);
            text-align: left;
            margin-right: -0.5vh;        
        }    
        .zaal{
            justify-self: left; 
            text-align: right;    
            transform: rotate(-90deg);
            margin-left: -0.5vh;
        } 
        .o{
            transform: rotate(90deg);
            text-align: left;
            margin-right: -0.5vh;        
        }   
        .kal{
            grid-area: k;
            text-align: center;
        }  
        .huis{
            grid-area: h;
            text-align: center;
        }        
    }
    
    @media only screen and (max-width: 1023px) {
        .agile {
            display: none;
        }
        .dropdownlistO {
            display: none;
            
        }
    } 
    @media only screen and (min-width: 1023px) {
        .solo {
            display: none;
        }
    }      


    @media only screen and (min-width: 1024px) {
        .grid-sizer,
        .grid-item{
            width: 50%; 
        }
        .grid-item.twocol {
            width: 100%;
        }
        .grid-item.threecol {
            width: 100%;
        }
    }


    @media only screen and (min-width: 1824px) {
        .grid-sizer,
        .grid-item{
            width: 33.333%; 
        }
        .grid-item.twocol {
            width: 66.666%;
        }
        .grid-item.threecol {
            width: 100%;
        }
    }

    @supports not ((display: -ms-grid) or (display: grid)) {
      .info > * {
        width: 620px;
        margin: 0 auto;
      }
    }	
    
    /*-- agile --*/
    
    .wCaption .agile__dots {
        bottom: 0.2vh;
        left: 50%;
        position: absolute;
        -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
    }
    .wCaption .agile__dot {
      margin: 0 1vw;
    }
    .wCaption .agile__dot button {
        background-color: #f1f2eb;
        border: 1px solid #f1f2eb;
        cursor: pointer;
        display: block;
        height: 10px;
        font-size: 0;
        line-height: 0;
        margin: 0;
        padding: 0;
        -webkit-transition-duration: 0.3s;
              transition-duration: 0.3s;
        width: 10px;
    }
    .wCaption .agile__dot--current button, .agile__dot:hover button {
        background-color: #01514a;
        border: 1px solid #01514a;
    }
    
    .wCaption .agile__nav-button {
        font-family: 'Roboto', sans-serif;        
        background: transparent;
        border: none;
        color: #f1f2eb;
        cursor: pointer;
        font-size: 30px;
        position: absolute;
        bottom: 0vh;
        -webkit-transition-duration: 0.2s;
              transition-duration: 0.2s;
        width: 5vw;
    }
    .wCaption .agile__nav-button:hover {
        color: #01514a;
        font-size: 30px;
    }
    .wCaption .agile__nav-button--prev {
        left: 0.4vw;
        text-align: left;
    }
    .wCaption .agile__nav-button--next {
        right: 1vw;
        text-align: right;
    }
    
    .wCaption .agile__caption{
        font-size: calc(10px + 0.2vw);
        padding-top: 0.25vh;
        text-align: right;
        height: 0;
    }
    
    figcaption{
        font-size: calc(10px + 0.2vw);
        padding-top: 0.25vh;
        text-align: right;
    }

    .agile__dots {
        bottom: 0.2vh;
        left: 50%;
        position: absolute;
        -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
    }
    .agile__dot {
      margin: 0 1vw;
    }
    .agile__dot button {
        background-color: #f1f2eb;
        border: 1px solid #f1f2eb;
        cursor: pointer;
        display: block;
        height: 10px;
        font-size: 0;
        line-height: 0;
        margin: 0;
        padding: 0;
        -webkit-transition-duration: 0.3s;
              transition-duration: 0.3s;
        width: 10px;
    }
    .agile__dot--current button, .agile__dot:hover button {
        background-color: #01514a;
        border: 1px solid #01514a;
    }
    
    .agile__nav-button {
        font-family: 'Roboto', sans-serif;        
        background: transparent;
        border: none;
        color: #f1f2eb;
        cursor: pointer;
        font-size: 30px;
        position: absolute;
        bottom: 0.0vh;
        -webkit-transition-duration: 0.2s;
              transition-duration: 0.2s;
        width: 5vw;
    }
    .agile__nav-button:hover {
        color: #01514a;
        font-size: 30px;
    }
    .agile__nav-button--prev {
        left: 0.4vw;
        text-align: left;
    }
    .agile__nav-button--next {
        right: 1vw;
        text-align: right;
    }


    @font-face {
        font-family: 'Roboto';
        src: url('./fonts/Roboto/Roboto-Light.ttf');
        font-weight: 300;
        font-style: normal;
    }  

    @font-face {
        font-family: 'Roboto';
        src: url('./fonts/Roboto/Roboto-Medium.ttf');
        font-weight: 500;
        font-style: normal;
    }      

    @font-face {
        font-family: 'Roboto';
        src: url('./fonts/Roboto/Roboto-LightItalic.ttf');
        font-weight: 300;
        font-style: italic;
    }  
      
    @font-face {
        font-family: 'Roboto';
        src: url('./fonts/Roboto/Roboto-MediumItalic.ttf');
        font-weight: 500;
        font-style: italic;
    }     

    @font-face {
        font-family: 'Montserrat';
        src: url('./fonts/Montserrat/Montserrat-Light.ttf');
        font-weight: 300;
        font-style: normal;
    }  

    @font-face {
        font-family: 'Montserrat';
        src: url('./fonts/Montserrat/Montserrat-Medium.ttf');
        font-weight: 500;
        font-style: normal;
    }      

    @font-face {
        font-family: 'Montserrat';
        src: url('./fonts/Montserrat/Montserrat-LightItalic.ttf');
        font-weight: 300;
        font-style: italic;
    }  
      
    @font-face {
        font-family: 'Montserrat';
        src: url('./fonts/Montserrat/Montserrat-MediumItalic.ttf');
        font-weight: 500;
        font-style: italic;
    }   
    
</style>

