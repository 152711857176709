<template>
    <div class="overHuisIMG3">
        <div class="imgGummieLink">    
            <a href="https://www.natuurpunt.be/afdelingen/natuurpunt-mechels-rivierengebied-kern-mechelen" target="_blank" rel="noopener noreferrer"><v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/over/huis/gummNils.png" src-placeholder="images/over/huis/placeHolderHuis.png"/></a>
        </div>         
        <p class="txtGummie"  style="margin-top: 1vh; padding-left: 7vw; padding-right: 3vw;"><em>Het mag dan een cliché zijn, maar mijn grootste droom is een solidaire en natuurinclusieve wereld waarin de mens in evenwicht leeft met zijn omgeving.</em> --Nils</p>
    </div>     
</template>

<script>
export default {
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

    
    @media only screen and (min-width: 0px) {
     

    }    
    
    @media only screen and (min-width: 1824px) {
        .overHuisIMG3{
            padding-bottom: 1vh;
        }
    }    
    
</style>
