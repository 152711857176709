<template>
    <div>
        <a href="https://webmail.gumm-cohousing.be/">webmail</a> - <a href="https://launchpad.37signals.com/signin">basecamp</a> - <a href="">intranet?</a>
    </div>    
</template>

<script>
export default {
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

    
    @media only screen and (min-width: 0px) {
     

    }    
    
    @media only screen and (min-width: 1536px) {

    }    
    
</style>
