<template>
    <div class="wCaption tuinIMG5">
        <agile :options="AgileOptions" :initialSlide=0 @after-change="e => currentSlide = e.currentSlide">
            <template slot="caption">{{ captions[currentSlide] }}</template>
            <div class="slide"><v-lazy-image  style="padding-left: 0px; padding-right: 0px;" src="images/tuin/tuinGUMMstofuilRups-01.jpg" src-placeholder="images/tuin/placeholderDierLandscape.png"/></div>
            <div class="slide"><v-lazy-image  style="padding-left: 0px; padding-right: 0px;" src="images/tuin/tuinGUMMatalanta-02.jpg" src-placeholder="images/tuin/placeholderDierLandscape.png"/></div>
            <div class="slide"><v-lazy-image  style="padding-left: 0px; padding-right: 0px;" src="images/tuin/tuinGUMMranonkelbij-01.jpg" src-placeholder="images/tuin/placeholderDierLandscape.png"/></div>
            <template slot="prevButton">←</template>
            <template slot="nextButton">→</template>        
        </agile>        
        <div class="solo"><v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/tuin/tuinGUMMstofuilRups-01.jpg" src-placeholder="images/tuin/placeholderDierLandscape.png"/><figcaption>De Gewone Stofuilrups foerageert ´s nachts en verbergt zich overdag diep in de vegetatie. De soort overwintert als rups en verpopt zich in een stevige cocon in de grond. Tot zijn waardplanten rekent hij diverse kruidachtige planten, waaronder muur, zuring, dovenetel en sleutelbloem.      
        </figcaption></div>   
    </div>     
</template>

<script>  
    import { VueAgile } from 'vue-agile'    
    export default {    
            data: function () {
                "use strict";
                return {
                    AgileOptions: {
                                slidesToShow: 1,
                                navButtons: false,         
                                responsive: [
                                    {
                                        breakpoint: 768,
                                        settings: {
                                            navButtons: true,
                                        }
                                    }
                                ]                    
                      },
                    currentSlide: 0,
                    captions: [
                        'De Gewone Stofuilrups foerageert ´s nachts en verbergt zich overdag diep in de vegetatie. De soort overwintert als rups en verpopt zich in een stevige cocon in de grond. Tot zijn waardplanten rekent hij diverse kruidachtige planten, waaronder muur, zuring, dovenetel en sleutelbloem.',
                        'De Atalanta vlinder is genoemd naar Atalante, jageres uit de Griekse mythologie. Atalante wilde slechts degene huwen die haar in een wedloop zou verslaan. Hippomenes, die de uitdaging aannam, bracht drie gouden appels mee die hij onderweg liet vallen. Atalante kon de verleiding  niet weerstaan en verloor zo de wedstrijd. De trekvlindersoort wordt ook als eens afgeleid door de bloemen op hun weg en ook door de (niet zo gouden) peren in de gummtuin.',
                        'De vrouwtjes van de Ranonkelbij nestelen zich in holle stengels en dood hout, maar ook bijenhotels. De buitenste laag van de nestgang wordt dan meestal met kleine steentjes afgewerkt. De soort heeft een grote voorkeur voor Boterbloemen, waarin de mannetjes kunnen worden aangetroffen als ze daarin een dutje doen. Hun kaken gaan dan in een soort kramphouding waardoor ze kunnen uitrusten.'
                    ]
                };          
            },
            components: {
                'agile': VueAgile,
              },         
            methods: {
                lFilter: function(e) {
                    this.$parent.mfilter(e);         
                    window.scrollTo(0,0); 
                }

            }
        }
</script>

<style>

    @media only screen and (min-width: 0px) {

        .tuinIMG5 {
            margin-top: 0vh;
            margin-right: 0vw;
            margin-left: 0vw;
            margin-bottom: 2.5vh;            

        }     

    }
    
    @media only screen and (min-width: 1024px) {

        .tuinIMG5 {
            margin-top: -2.5vh;
            margin-right: 0vw;
            margin-left: 0vw;
            margin-bottom: 10vh;            

        }
    }
      

    @media only screen and (min-width: 1824px) {

        .tuinIMG5 {
            margin-top: 0vh;
            margin-right: 20vw;
            margin-left: 2vw;
            margin-bottom: 12vh;
        }
    }

    .tuinIMG5 .agile__caption{
        text-align: left;
        padding-left: 20%;
        padding-right: 20%;
        padding-top: 1vh;        
    }
    .tuinIMG5 .agile__dots {
        bottom: 1.5vh;
    }
    
    .tuinIMG5 .agile__nav-button {    
        bottom: 1.2vh;
    }
    .tuinIMG5 figcaption{
        text-align: left;
    }    

</style>