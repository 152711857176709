<template>
    <div class="dividerE">
        <img style="padding-left: 0px; padding-right: 0px;" src="images/details/dividerE.png">
    </div>    
</template>

<script>  
    export default {    
            methods: {
                lFilter: function(e) {
                    this.$parent.mfilter(e);         
                    window.scrollTo(0,0); 
                }

            }
        }
</script>

<style>
    .dividerE{
        margin-top: -2.5vh;
        margin-left: -50vw;
        width: 200%;
    } 

    @media only screen and (min-width: 1024px) {    
        .dividerE{
            margin-top: -2.5vh;
            margin-left: -5vw;
            width: 120%;
        }
    }

    @media only screen and (min-width: 1824px) {    
        .dividerE{
            margin-top: -2.5vh;
            margin-left: -5vw;
            width: 120%;
        }
    }

</style>