<template>
    <div class="tuinIMG1">    
        <v-lazy-image style="padding-left: 1vw; padding-right: 1vw;" src="images/tuin/20200126_praatplan.png"  src-placeholder="images/tuin/placeholderPraatplan.png"/>        
    </div>    
</template>

<script>  
    export default {    
            methods: {
                lFilter: function(e) {
                    this.$parent.mfilter(e);         
                    window.scrollTo(0,0); 
                }

            }
        }
</script>

<style>


    @media only screen and (min-width: 0px) {

    }
    
    @media only screen and (min-width: 1024px) {

        .tuinIMG1{
            margin-left: -2vw;
            margin-right: 10vw;
        }
    }
      

    @media only screen and (min-width: 1824px) {

        .tuinIMG1 {
            margin-left: -2vw;
            margin-right: 0vw;            
        }

    }     
</style>