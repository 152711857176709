<template>
    <div>
        <div class="imgGummieLink">    
            <a href="http://www.dekuub.be/" target="_blank" rel="noopener noreferrer"><v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/over/huis/gummGudrunJoost.png" src-placeholder="images/over/huis/placeHolderHuis.png"/></a>
        </div>         
        <p class="txtGummie"  style="margin-top: 1vh;padding-left: 5vw; padding-right: 1vw;"><em>Cohousing, voor ons is dat: een grote tuin, midden in 't stad! En ontdekten we er de voorbije jaren het plezier van: </em></p>
        <p class="txtGummie"  style="margin-top: 0vh;padding-left:6vw; padding-right: 1vw;"><em>
         koken met Joke, met Marcske meubelkes maken, autodelen met Ria Bloem, Duveltjes drinken met Wim, vergaderen  :-0, kamperen met de Gieters, met Mieke komkommers “veryangen” ;-), vlinderzotten en motten vangen met Nils, tekenplezier delen met Jasmien…</em></p>
         <p class="txtGummie"  style="margin-top: 0vh;padding-left:5vw; padding-right: 1vw;"><em>
        Wij kijken er ondertussen naar uit om dit lijstje de komende jaren serieus uit te breiden. 
        Cohousing, voor ons is dit: een leuke en leerrijke ontdekkingstocht! 
        </em> --Gudrun & Joost</p>
    </div>     
</template>

<script>
export default {
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

    
    @media only screen and (min-width: 0px) {
     

    }    
    
    @media only screen and (min-width: 1824px) {

    }    
    
</style>