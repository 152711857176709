<template>
    <div class="overBouwIMG1">
        <agile :options="AgileOptions" :initialSlide=20>
            <div class="slide"><v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/over/bouw/wwwGUMMhoek1800-01.jpg" src-placeholder="images/over/bouw/placeholderHoek.png"/> </div>
            <div class="slide"><v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/over/bouw/wwwGUMMhoek1800-02.jpg" src-placeholder="images/over/bouw/placeholderHoek.png"/></div>
            <div class="slide"><v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/over/bouw/wwwGUMMhoek1800-04.jpg" src-placeholder="images/over/bouw/placeholderHoek.png"/></div>
            <div class="slide"><v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/over/bouw/wwwGUMMhoek1800-05.jpg" src-placeholder="images/over/bouw/placeholderHoek.png"/></div>
            <div class="slide"><v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/over/bouw/wwwGUMMhoek1800-07.jpg" src-placeholder="images/over/bouw/placeholderHoek.png"/></div>
            <!--<div class="slide"><v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/over/bouw/wwwGUMMhoek1800-09.jpg" src-placeholder="images/over/bouw/placeholderHoek.png"/></div>-->
            <div class="slide"><v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/over/bouw/wwwGUMMhoek1800-14.jpg" src-placeholder="images/over/bouw/placeholderHoek.png"/></div> 
            <div class="slide"><v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/over/bouw/wwwGUMMhoek1800-15.jpg" src-placeholder="images/over/bouw/placeholderHoek.png"/></div> 
            <!---<div class="slide"><v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/over/bouw/wwwGUMMhoek1800-16.jpg" src-placeholder="images/over/bouw/placeholderHoek.png"/></div> -->
            <div class="slide"><v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/over/bouw/wwwGUMMhoek1800-18.jpg" src-placeholder="images/over/bouw/placeholderHoek.png"/></div>      
            <div class="slide"><v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/over/bouw/wwwGUMMhoek1800-21.jpg" src-placeholder="images/over/bouw/placeholderHoek.png"/></div>      
            <div class="slide"><v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/over/bouw/wwwGUMMhoek1800-22.jpg" src-placeholder="images/over/bouw/placeholderHoek.png"/></div>
            <div class="slide"><v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/over/bouw/wwwGUMMhoek1800-25.jpg" src-placeholder="images/over/bouw/placeholderHoek.png"/></div>      
            <!--<div class="slide"><v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/over/bouw/wwwGUMMhoek1800-27.jpg" src-placeholder="images/over/bouw/placeholderHoek.png"/></div> -->
            <div class="slide"><v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/over/bouw/wwwGUMMhoek1800-28.jpg" src-placeholder="images/over/bouw/placeholderHoek.png"/></div>
            <div class="slide"><v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/over/bouw/wwwGUMMhoek1800-29.jpg" src-placeholder="images/over/bouw/placeholderHoek.png"/></div> 
            <div class="slide"><v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/over/bouw/wwwGUMMhoek1800-30.jpg" src-placeholder="images/over/bouw/placeholderHoek.png"/></div>             
            <div class="slide"><v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/over/bouw/wwwGUMMhoek1800-33.jpg" src-placeholder="images/over/bouw/placeholderHoek.png"/></div>             
            <div class="slide"><v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/over/bouw/wwwGUMMhoek1800-35.jpg" src-placeholder="images/over/bouw/placeholderHoek.png"/></div>    
            <div class="slide"><v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/over/bouw/wwwGUMMhoek1800-38.jpg" src-placeholder="images/over/bouw/placeholderHoek.png"/></div>       
            <div class="slide"><v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/over/bouw/wwwGUMMhoek1800-40.jpg" src-placeholder="images/over/bouw/placeholderHoek.png"/></div>                                       
            <div class="slide"><v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/over/bouw/wwwGUMMhoek1800-42.jpg" src-placeholder="images/over/bouw/placeholderHoek.png"/></div>                                
            <template slot="prevButton">←</template>
            <template slot="nextButton">→</template>        
        </agile>
        <div class="solo"><v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/over/bouw/wwwGUMMhoek1800-42.jpg" src-placeholder="images/over/bouw/placeholderHoek.png"/></div>
    </div>
</template>

<script>
    import { VueAgile } from 'vue-agile'

    export default {
        data: function () {
            "use strict";
            return {
                AgileOptions: {
                            slidesToShow: 1,
                            navButtons: false,         
                            responsive: [
                                {
                                    breakpoint: 768,
                                    settings: {
                                        navButtons: true,
                                    }
                                }
                            ]                    
                  }                
            };          
        },
        components: {
            'agile': VueAgile,
          }, 
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>    

    @media only screen and (min-width: 0px) {
        
        .overBouwIMG1{
        }       
        
    }
    
    @media only screen and (min-width: 1024px) {

        .overBouwIMG1{

        }

    } 
      

    @media only screen and (min-width: 1824px) {

        .overBouwIMG1{

        }

    }  


</style>