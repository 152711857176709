<template>
    <div class="infoIMG">
        <v-lazy-image style="" src="images/over/gummContactDromenMag.png" src-placeholder="images/over/placeholderContactDromenMag.png"/>
    </div>    
</template>

<script>  
    export default {    
            methods: {
                lFilter: function(e) {
                    this.$parent.mfilter(e);         
                    window.scrollTo(0,0); 
                }

            }
        }
</script>

<style>
   
    @media only screen and (min-width: 1024px) {    
        .infoIMG{
            padding-left:3vw;
            padding-right:2vw;

        }
    }

    @media only screen and (min-width: 1824px) {    
        .infoIMG{
            padding-left:2vw;
            padding-right:1vw;
        }
    }

</style>