<template>
        <div class="tuinIMG3">    
            <v-lazy-image style="padding-left: 1vw; padding-right: 1vw;" src="images/tuin/werfGUMM012021lr-0304.png" src-placeholder="images/tuin/placeholderDierPortrait.png"/>
        </div>  
</template>

<script>  
    export default {    
            methods: {
                lFilter: function(e) {
                    this.$parent.mfilter(e);         
                    window.scrollTo(0,0); 
                }

            }
        }
</script>

<style>

    @media only screen and (min-width: 0px) {
        
        .tuinIMG3 {

        }
    }
    
    @media only screen and (min-width: 1024px) {
        .tuinIMG3 {
            margin-top: -1vh;
            margin-right: 10vw;
            margin-left: 15vw;
            margin-bottom: 2.5vh;

        }
    }
      

    @media only screen and (min-width: 1824px) {

        .tuinIMG3 {
            margin-top: 0vh;
            margin-right: 1.5vw;
            margin-left: 10vw;
            margin-bottom: 5vh;
        }

    }  

</style>