<template>
    <div class = "zaalTXT">  
        <p class="title">de gummzaal</p>
        <p class='p1' lang="nl"> Gumm is dus meer dan alleen cohousing, het omvat ook een <span class= "linkInText" @click="lFilter(4)">oud kerkgebouw</span> dat we ambiëren om het helemaal te doen heropleven tot een ontmoetingsplek waar niet alleen wijzelf, maar ook ondernemers en gemeenschappen uit de buurt hun ding mee kunnen doen. Dit kan gaan van een wekelijke buurtcafé tot een wekelijke yogales, of vb. een tentoonstellings&shy;ruimte voor een lokale kunstenaar, een lentefeest, een theater of muziekvoorstelling, maar vb. ook een ruimte voor lezingen en workshops. De focus ligt daarbij op activiteiten die de sociale cohesie tussen de bewoners, de buurt en Mechelen (en zijn natuur) stimuleren. </p>
        <p class='p1' lang="nl"> 
        Om onze gummzaal om te bouwen tot een multi&shy;functionele plek vol mogelijkheden, zijn er heel wat infrastructurele ingrepen nodig. Met het volledig project van Gumm cohousing deden we mee aan <a href="https://klimaatneutraal.mechelen.be/paraat-voor-het-klimaat-winnaars-2020" target="_blank" rel="noopener noreferrer">Paraat Voor het Klimaat</a> in 2020. En hiervoor organiseerde we in 2021 ook een <a href="https://www.growfunding.be/nl/projects/gumm" target="_blank" rel="noopener noreferrer">crowdfunding</a> met een focus op de gummzaal. We verzanelden hiervoor extra budget om een nieuwe vloer en vloererwarming aan te leggen. Zo wordt de verwarming meer klimaatneutraal. Daarnaast gaan we ook de akoestiek verbeteren door een akoestische spuitpleister te laten aanbrengen. Op die manier worden de mogelijkheden in de zaal sterk uitgebreid. </p>
        <p class='p1' lang="nl"> De beschikbaarheid van de gummzaal is gepland voor 2023. Ondertussen werken we ook verder hoe we dit praktisch en administratief gaan organiseren.</p>
        <a href="https://klimaatneutraal.mechelen.be/paraat-voor-het-klimaat-winnaars-2020" target="_blank" rel="noopener noreferrer"><img src="images/zaal/MK_H_sRGB.png" class="MKNlogo"></a>        
    </div>    
</template>

<script>  
    export default {    
            methods: {
                lFilter: function(e) {
                    this.$parent.mfilter(e);         
                    window.scrollTo(0,0); 
                }
            }
        }
</script>

<style>
    
    @media only screen and (min-width: 0px) {
    
        .zaalTXT{
            padding-left: 0vw;
            padding-right: 0vw;
        }
        
        .MKNlogo{
            float: right;
            max-width: 250px;
        }
        
        .MKNlogo:hover {
            opacity: 0.9;
            transform: scale(0.95);
            -webkit-transition: .05s ease-in-out;
            -o-transition: .05s ease-in-out;
            transition: .05s ease-in-out;        
        } 
    }
    
    @media only screen and (min-width: 1024px) {
    
        .zaalTXT{
            padding-left: 0vw;
            padding-right: 0vw;
            margin-bottom: 0vh;                        

        }
        
 
    }       

    
    @media only screen and (min-width: 1824px) {
        
        .zaalTXT{
            padding-left: 0vw;
            padding-right: 0vw;
            padding-top: 1vh;            

        }
        
    }

</style>