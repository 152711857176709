<template>
    <div class="overZaalIMG1">
        <div class="wCaption"> 
            <agile :options="AgileOptions" :initialSlide=0 @after-change="e => currentSlide = e.currentSlide">
                <template slot="caption">{{ captions[currentSlide] }}</template>
                <div class="slide"><v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/over/zaal/histo1925GUMMbouwplanK-VAZ-00.jpg" src-placeholder="images/over/zaal/placeholderHistoZaal.png"/></div>
                <div class="slide"><v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/over/zaal/histo1925GUMM1steSteen1508-02.jpg" src-placeholder="images/over/zaal/placeholderHistoZaal.png"/></div>
                <div class="slide"><v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/over/zaal/histo1925GUMMzaal02.jpg" src-placeholder="images/over/zaal/placeholderHistoZaal.png"/></div>  
                <div class="slide"><v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/over/zaal/werfGUMM012021lr-12.jpg" src-placeholder="images/over/zaal/placeholderHistoZaal.png"/></div> 
                <template slot="prevButton">←</template>
                <template slot="nextButton">→</template>  
            </agile>
            <div class="solo"><v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/over/zaal/histo1925GUMM1steSteen1508-02.jpg" src-placeholder="images/over/zaal/placeholderHistoZaal.png" /><figcaption>1925, 1ste steenlegging kerkgebouw op 15 augustus.</figcaption></div>
        </div>  
    </div>

</template>

<script>
    import { VueAgile } from 'vue-agile'

    export default {
        name: 'overZaalIMG1',    
        data: function () {
            "use strict";
            return {
                AgileOptions: {
                            slidesToShow: 1,
                            navButtons: false,         
                            responsive: [
                                {
                                    breakpoint: 768,
                                    settings: {
                                        navButtons: true,
                                    }
                                }
                            ]                    
                  },
                currentSlide: 0,
                captions: [
                    '1925, bouwplan zijaanzicht kerkgebouw.',
                    '1925, 1ste steenlegging kerkgebouw op 15 augustus.',
                    "1925, bouwwwerf kerkgebouw en hoekhuis, uit het boek 'Mechelen, de memoires van een stad (1986)'.",
                    "2021, spitsbogen oksaal."
                ]                
            };          
        },
        components: {
            'agile': VueAgile,
          }, 
    }
</script>

<style>    
    
    @media only screen and (max-width: 768px) {
        
        .overZaalIMG1{
            padding-top: 0vh;
            padding-left: 1vw;
            padding-right: 2vw;
            padding-bottom: 0vh;            
        }        
        
    }
    
    @media only screen and (min-width: 1024px) {

        .overZaalIMG1{
            padding-top: 1vh;
            padding-left: 3vw;
            padding-right: 1vw;
            padding-bottom: 1vh; 
            margin-bottom: 5vh;
        }      

    }   

    @media only screen and (min-width: 1824px) {

        .overZaalIMG1{
            padding-top: 1vh;
            padding-left: 3vw;
            padding-right: 3vw;
            padding-bottom: 1vh;            
        }

    }     
    
    
</style>