<template>
    <div>
        <div class="imgGummieLink">    
            <a href="https://www.youtube.com/channel/UCTpviEKCCx1hHRqWuTO5Lgw" target="_blank" rel="noopener noreferrer"><v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/over/huis/gummLeander.png" src-placeholder="images/over/huis/placeHolderHuis.png"/></a>
        </div>         
        <p class="txtGummie"  style="margin-top: 1vh;padding-left: 3vw; padding-right: 3.5vw;"><em>Wanneer je een nieuw muziekstuk instudeert en weken-, zelfs maandenlang het gevoel hebt dat je alleen maar zit te sukkelen en maar niet vooruit komt, tot je op een dag een beetje moedeloos voor je instrument gaat zitten en je handen over het klavier glijden en je plots beseft dat je muziek aan het maken bent: dat is het gelukzaligste gevoel dat ik ken. </em> --Leander</p>
    </div>     
</template>

<script>
export default {
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

    
    @media only screen and (min-width: 0px) {
     

    }    
    
    @media only screen and (min-width: 1824px) {

    }    
    
</style>