<template>
    <div class="dividerA">
        <img style="padding-left: 1vw; padding-right: 1vw;" src="images/details/dividerA.png">
    </div>    
</template>

<script>  
    export default {    
            methods: {
                lFilter: function(e) {
                    this.$parent.mfilter(e);         
                    window.scrollTo(0,0); 
                }

            }
        }
</script>

<style>
    .dividerA{
        margin-top: -2.5vh;
        margin-left: -35vw;
        width: 200%;

    } 
    @media only screen and (min-width: 1024px) {    
        .dividerA{
            margin-top: -4vh;
            margin-left: -5vw;
            width: 120%;
        }
    }

</style>