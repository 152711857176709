<template>
    <div class="wCaption tuinIMG7">
        <agile :options="AgileOptions" :initialSlide=0 @after-change="e => currentSlide = e.currentSlide">
            <template slot="caption">{{ captions[currentSlide] }}</template>
            <div class="slide"><v-lazy-image  style="padding-left: 0px; padding-right: 0px;" src="images/tuin/tuinGUMMsamenspelen-01.jpg" src-placeholder="images/tuin/placeholderDierLandscape.png"/></div>
            <div class="slide"><v-lazy-image  style="padding-left: 0px; padding-right: 0px;" src="images/tuin/tuinGUMMsamenspelen-02.jpg" src-placeholder="images/tuin/placeholderDierLandscape.png"/></div>
            <div class="slide"><v-lazy-image  style="padding-left: 0px; padding-right: 0px;" src="images/tuin/tuinGUMMsamenspelen-03.jpg" src-placeholder="images/tuin/placeholderDierLandscape.png"/></div>
            <div class="slide"><v-lazy-image  style="padding-left: 0px; padding-right: 0px;" src="images/tuin/tuinGUMMvinderzotten-01.jpg" src-placeholder="images/tuin/placeholderDierLandscape.png"/></div>
            <div class="slide"><v-lazy-image  style="padding-left: 0px; padding-right: 0px;" src="images/tuin/tuinGUMMvinderzotten-02.jpg" src-placeholder="images/tuin/placeholderDierLandscape.png"/></div>
            <div class="slide"><v-lazy-image  style="padding-left: 0px; padding-right: 0px;" src="images/tuin/tuinGUMMvinderzotten-03.jpg" src-placeholder="images/tuin/placeholderDierLandscape.png"/></div>
            <template slot="prevButton">←</template>
            <template slot="nextButton">→</template>        
        </agile>        
        <div class="solo"><v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/tuin/tuinGUMMvinderzotten-03.jpg" src-placeholder="images/tuin/placeholderDierLandscape.png"/><figcaption>Vlindervinders en Mottenzotten, 2019.
        </figcaption></div>   
    </div>     
</template>

<script>  
    import { VueAgile } from 'vue-agile'    
    export default {    
            data: function () {
                "use strict";
                return {
                    AgileOptions: {
                                slidesToShow: 1,
                                navButtons: false,         
                                responsive: [
                                    {
                                        breakpoint: 768,
                                        settings: {
                                            navButtons: true,
                                        }
                                    }
                                ]                    
                      },
                    currentSlide: 0,
                    captions: [
                        'Samen bouwen aan een kindvriendelijke gedeelde tuin, 2018.',
                        'Samen bouwen aan een kindvriendelijke gedeelde tuin, 2018.',
                        'Samen bouwen aan een kindvriendelijke gedeelde tuin, 2018.',
                        'Vlindervinders en Mottenzotten, 2019.',
                        'Vlindervinders en Mottenzotten, 2019.',
                        'Vlindervinders en Mottenzotten, 2019, foto: Leo de Nijn.',
                    ]
                };          
            },
            components: {
                'agile': VueAgile,
              },         
            methods: {
                lFilter: function(e) {
                    this.$parent.mfilter(e);         
                    window.scrollTo(0,0); 
                }

            }
        }
</script>

<style>

    @media only screen and (min-width: 0px) {

        .tuinIMG7 {
            margin-top: 0vh;
            margin-right: 0vw;
            margin-left: 0vw;
            margin-bottom: 2.5vh;            

        }     

    }
    
    @media only screen and (min-width: 1024px) {

        .tuinIMG7 {
            margin-top: 0vh;
            margin-right: 10vw;
            margin-left: 2vw;
            margin-bottom: 5vh;            

        }
    }
      

    @media only screen and (min-width: 1824px) {

        .tuinIMG7 {
            margin-top: -2.5vh;
            margin-right: 2.5vw;
            margin-left: 5vw;
            margin-bottom: 5vh;
        }
    }

  

</style>