<template>
    <div>
        <p class="title">nieuwsbrief</p>
        <p class="p1"  lang="nl">
            Wil je graag op de hoogte gehouden worden via een nieuwsbrief? Dat kan door je in te schrijven via deze <a href='https://mailchi.mp/5e1ae67217eb/nieuwsbrief-gumm-cohousing' target="_blank" rel="noopener noreferrer"> link</a>. De nieuwsbrief verschijnt minimaal 4 keer per jaar. Een lijst van alle nieuwsbrieven vind je <a href='https://us11.campaign-archive.com/home/?u=64adfef4a68d8b01f31b40099&id=adac53b621' target="_blank" rel="noopener noreferrer">hier</a>.      
         </p>          
    </div>    
</template>

<script>
export default {

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>