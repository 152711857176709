<template>
    <div class="tuinIMG4">    
        <v-lazy-image style="padding-left: 1vw; padding-right: 1vw;" src="images/tuin/werfGUMM092020lr-13.jpg" src-placeholder="images/tuin/placeholderTuin.png"/>
    </div>    
</template>

<script>  
    export default {    
            methods: {
                lFilter: function(e) {
                    this.$parent.mfilter(e);         
                    window.scrollTo(0,0); 
                }

            }
        }
</script>

<style>



    @media only screen and (min-width: 0px) {
             .tuinIMG4 {
            margin-top: 0vh;
            margin-right: 2vw;
            margin-left: 1vw;
            margin-bottom: 5vh;            

        }   

    }
    
    @media only screen and (min-width: 1024px) {

        .tuinIMG4 {
            margin-top: -2.5vh;
            margin-right: -2vw;
            margin-left: 5vw;
            margin-bottom: 5vh;            

        }
    }
      

    @media only screen and (min-width: 1824px) {

        .tuinIMG4 {
            margin-top: 0vh;
            margin-right: 1.5vw;
            margin-left: 0vw;
            margin-bottom: 5vh;
        }
    }

</style>