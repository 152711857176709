<template>
    <div class="overZaalIMG2">
        <div class="wCaption"> 
            <agile :options="AgileOptions" :initialSlide=2 @after-change="e => currentSlide = e.currentSlide">
                <template slot="caption">{{ captions[currentSlide] }}</template>
                <div class="slide"><v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/over/zaal/histo1992GUMMzaal-02.jpg" src-placeholder="images/over/zaal/placeholderWerfZaal.png"/></div>
                <div class="slide"><v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/over/zaal/histo2021GUMMzaal-02.jpg" src-placeholder="images/over/zaal/placeholderWerfZaal.png"/></div>   
                <div class="slide"><v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/over/zaal/werfGUMM012021lr-11.jpg" src-placeholder="images/over/zaal/placeholderWerfZaal.png"/></div> 
                <div class="slide"><v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/over/zaal/werfGUMM012021lr-09.jpg" src-placeholder="images/over/zaal/placeholderWerfZaal.png"/></div> 
                <template slot="prevButton">←</template>
                <template slot="nextButton">→</template>  
            </agile>
            <div class="solo"><v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/over/zaal/histo2021GUMMzaal-02.jpg" src-placeholder="images/over/zaal/placeholderWerfZaal.png"/><figcaption>2021</figcaption></div>
        </div>  
    </div>

</template>

<script>
    import { VueAgile } from 'vue-agile'

    export default {
        name: 'overZaalIMG1',    
        data: function () {
            "use strict";
            return {
                AgileOptions: {
                            slidesToShow: 1,
                            navButtons: false,         
                            responsive: [
                                {
                                    breakpoint: 768,
                                    settings: {
                                        navButtons: true,
                                    }
                                }
                            ]                    
                  },
                currentSlide: 2,
                captions: [
                    '1992, © Agentschap Onroerend Erfgoed',
                    '2021',
                    '2021',
                    '2021'
                ]                
            };          
        },
        components: {
            'agile': VueAgile,
          }, 
    }
</script>

<style>    
    
    @media only screen and (min-width: 0px) {
               
        
    } 

    @media only screen and (min-width: 1824px) {

        .overZaalIMG2{
            margin-top: 3vh;
            padding-left: 2vw;
            padding-right: 2vw; 
            margin-bottom: 7vh;          
        }

    }     
    
    
</style>