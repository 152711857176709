<template>
    <div>
        <div class="imgGummieLink">    
            <a href="https://vgtleren.be" target="_blank" rel="noopener noreferrer"><v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/over/huis/gummMieke.png" src-placeholder="images/over/huis/placeHolderHuis.png"/></a>
        </div>         
        <p class="txtGummie"  style="margin-top: 1vh;padding-left: 3vw; padding-right: 3.5vw;"><em>Een droom dat echt geworden is. Ecologische waarden erop nahouden en biologisch leven. Delen van onze passies, ruimtes, talenten, vaardigheden. Leren van elkaar en in VGT kunnen communiceren. Luisteren, lachen, spelen en lekker gezond koken. Genieten van alle authentieke mensen, groot en klein. Mijn deur die altijd open zal staan voor spelende kinderen, delende buren en binnen&shy;lopende huisdieren. Een atelier met hoge grote ramen uitkijkend op de tuin en het wisselende weer, waarop inspiratie volop kan stromen. </em> --Mieke</p>
    </div>     
</template>

<script>
export default {
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

    
    @media only screen and (min-width: 0px) {
     

    }    
    
    @media only screen and (min-width: 1824px) {

    }    
    
</style>