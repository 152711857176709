<template>
    <div>
        <p class="title">gummkalender</p>    
        <p class="p1">Voor meer info klik je op de evenementen of neem een kijkje op <a href="https://www.facebook.com/gumm.cohousing" target="_blank">Facebook</a>.</p>         
        <div class="responsiveCal Month">   
            <iframe src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=2&amp;bgcolor=%23f1f2eb&amp;ctz=Europe%2FBrussels&amp;src=YzdrZTZ1NG41OGJlYm5oaDNyZ2FlM2RjbTBAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=NWozcjM4OHNwdGJvazYwaXEwY29wZnZoMzhAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=dG4yYTFtcjJoMmpxMWd0dXQ5dGw3ZTBqa2tAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;color=%234285F4&amp;color=%230B8043&amp;color=%23E67C73&amp;showTitle=0&amp;showPrint=0&amp;showTabs=0&amp;showCalendars=1&amp;showTz=0&amp;hl=nl&amp;mode=MONTH&amp;dates=20220605/20220620&amp;" style="border-width:0" width="800" height="600" frameborder="0" scrolling="no"></iframe>
        </div>   
        <div class="responsiveCal Agenda">   
            <iframe src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=2&amp;bgcolor=%23f1f2eb&amp;ctz=Europe%2FBrussels&amp;src=YzdrZTZ1NG41OGJlYm5oaDNyZ2FlM2RjbTBAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=NWozcjM4OHNwdGJvazYwaXEwY29wZnZoMzhAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=dG4yYTFtcjJoMmpxMWd0dXQ5dGw3ZTBqa2tAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;color=%234285F4&amp;color=%230B8043&amp;color=%23E67C73&amp;showTitle=0&amp;showPrint=0&amp;showTabs=0&amp;showCalendars=1&amp;showTz=0&amp;hl=nl&amp;mode=AGENDA&amp;dates=20220610/20220620&amp;" style="border-width:0" width="800" height="600" frameborder="0" scrolling="no"></iframe>
        </div>  
    </div>
</template>

<script>
export default {
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    .responsiveCal {
        position: relative; 
        padding-bottom: 75%; 
        height: 0; 
        overflow: hidden;
    }

    .responsiveCal iframe {

        position: absolute; 
        top:0; 
        width: 100%; 
        height: 100%;
        left: 50%;
        -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
                transform: translateX(-50%); 

    }    


    
    @media only screen and (max-width: 1024px) {
        .responsiveCal {
            min-height: 400px;

        }   


        .Month {
            display: none;
        }

    }    

    @media only screen and (min-width: 1024px) { 

        .Agenda {
            display: none;
        }        

    }  
    
    @media only screen and (min-width: 1824px) {
 
        .responsiveCal iframe {

            height: 80%;


        }    

        .Agenda {
            display: none;
        }        

    }    
    
</style>


