<template>
    <div class = "zaalIMG2">    
         <v-lazy-image style="padding-left: 0vw; padding-right: 0vw;" src="images/zaal/G21-crowdfunding-b2.jpg" src-placeholder="images/zaal/placeholderZaalWide.png"/>
    </div>    
</template>

<script>  
    export default {    
            methods: {
                lFilter: function(e) {
                    this.$parent.mfilter(e);         
                    window.scrollTo(0,0); 
                }

            }
        }
</script>

<style> 
    @media only screen and (min-width: 0px) {
        
        .zaalIMG2{
         
        }   
        
        .zaalIMG2:hover {
  
        }  
        
    }
    
    @media only screen and (min-width: 1024px) {

        .zaalIMG2{
            padding-right: 1vw;
        }      

    }   

    @media only screen and (min-width: 1824px) {

        .zaalIMG2{
            margin-top: 0vh;
            padding-right:1vw;
            padding-left:0.25vw;
        }

    }    
</style>