<template>
    <div>
        <p class="title">de gummtuin</p>
        <p class='p1'>We verbouwen niet alleen onze gebouwen, we transformeren ook de oude speelplaats tot een groene long.</p>
        <p>
            Dit gaan we doen:
            <ul>
                <li><div>In een groene oase bieden we kansen voor meer biodiversiteit, waterinfiltratie, gezonde lucht en een betere levenskwaliteit voor de buurt.</div></li>
                <li><div>Samen met de buurt organiseren we activiteiten die het buurtleven en ontmoeting versterken.</div></li>
                <li><div>Door samenwerking met o.a. de scouts en stad Mechelen vergroten we de impact van de ontharding.</div></li>
                <li><div>We kijken verder dan ons eigen perceel en zoeken verbinding met omliggende gebieden en organisaties.</div></li>
            </ul>
        </p>
        <p class='p1'> We doen dit niet alleen: samen met <a href="https://omgeving.vlaanderen.be/gummarushof-onthardt" target="_blank" rel="noopener noreferrer">Departement Omgeving</a> van de Vlaamse Overheid, <a href="https://gummarusolv.be/" target="_blank" rel="noopener noreferrer">Scouts Sint-Gummarus en Gidsen OLV</a> en <a href="https://www.mechelen.be/" target="_blank" rel="noopener noreferrer">Stad Mechelen</a> creëeren we voor de buurt groen en zuurstof. We doen dit voor passanten om van te genieten, voor kinderen om in te spelen en voor organisaties om activiteiten te organiseren. Momenteel wordt er hard gewerkt aan de uitvoeringsplannen.</p>
        <div class="VBKlogo">
        <a href="https://omgeving.vlaanderen.be/vlaanderen-breekt-uit-homepagina" target="_blank" rel="noopener noreferrer"><img style="padding-left: 0vw; padding-right: 0vw;" src="images/tuin/VlaanderenBreekUit-logo.png"></a></div>
     </div>    
</template>

<script>
export default {
  name: 'tuinTXT'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>   

    .VBKlogo:hover {
        opacity: 0.9;
        transform: scale(0.95);
        -webkit-transition: .05s ease-in-out;
        -o-transition: .05s ease-in-out;
        transition: .05s ease-in-out;        
    } 
    
    @media only screen and (min-width: 0px) {
        .VBKlogo{
            max-width: 100px;
            margin-left: 5vw;
            margin-bottom: 2.5vh;
            margin-top: 1vh;
            float: left;
        }        

    }    
    
    @media only screen and (min-width: 1024px) {
        .VBKlogo{
            max-width: 150px;
            margin-bottom: 5vh;
            margin-left: 2.5vw;
        }  
        
    }    

    @media only screen and (min-width: 1824px) {
        .VBKlogo{
            margin-bottom: 10vh;
            margin-left: 0.5vw;
        }  
        
    }  
    
</style>
