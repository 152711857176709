<template>
    <div class="overHuisTXT">
        <p class="title" style="margin-top: 3vh;">gummhuis</p>
        <div class="imgGummie overHuisTXTimg">    
            <v-lazy-image src="images/over/huis/gummTafel.png" src-placeholder="images/over/huis/placeHolderHuis.png"/>
        </div> 
        <p class='p1' style="margin-top: 3vh;">Het gummhuis wordt een verzamelplek voor de bewoners waar ze hun talenten en creativiteit kunnen uitoefenen, samen zijn met elkaar, met vrienden of familie of gewoon gebruik maken voor een activiteit die iets meer ruimte nodig heeft. Concreet zullen de bewoners in het gummhuis een keuken, een grote polyvalente ruimte, een bibliotheek, een speelkamer, hobbyruimtes en logeerkamers delen. Er zal op de gumm plek ook een wasbar en atelier gedeeld worden, en niet te vergeten: de <span class= "linkInText" @click="lFilter(9)">gummtuin</span>. Er wordt ook nagedacht over sommige van deze ruimtes open te stellen voor niet-bewoners, bijvoorbeeld de keuken en logeerkamers.</p>
    </div>    
</template>

<script>
    export default {
            methods: {
                lFilter: function(e) {
                    this.$parent.mfilter(e);         
                    window.scrollTo(0,0); 
                }

            }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

    
    @media only screen and (min-width: 0px) {
     

    }    
    
    @media only screen and (min-width: 1024px) {
        .overHuisTXT{
            padding-top: 5vh;
            padding-left: 2vw;
            padding-right: 5vw;
            margin-bottom: 5vh;
        }
        
        .overHuisTXTimg
        {
            padding-right: 10vw;
        }
    }   

    @media only screen and (min-width: 1824px) {
        .overHuisTXT{
            padding-top: 5vh;
            padding-left: 2vw;
            padding-right: 2vw;
            margin-bottom: 5vh;
        }
        
        .overHuisTXTimg
        {
            padding-right: 3vw;
        }
    }  
    
</style>
