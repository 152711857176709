<template>
    <div>
        <p class="title">de groep</p>    
        <p class="p1">Het verhaal van gumm gaat terug naar 2013, toen drie pioniers de handen in elkaar sloegen om aan een cohousing&shy;verhaal te werken binnen Mechelen. Er werd een vzw opge&shy;richt, <i>Samen Wonen Mechelen</i>, met als doel om enerzijds cohousing te promoten in de Mechelse regio en anderzijds om zelf een cohousin&shy;project op te starten om er zelf te gaan wonen.</p>
        <p class="p1">In de loop van 2014 werd de groep uitgebreid tot tien, vijftien, twintig personen. Er werd alvast nagedacht over een visie en over welke ruimtes er gedeeld zouden worden, en zo gingen we op zoek naar een locatie in Mechelen. Dankzij een tip van de Stad Mechelen kregen we in 2015 de Gummarus site in het vizier. Na een positieve haalbaarheids&shy;studie tekenden we in september 2016 de aankoopbelofte met 12 gezinnen. Tegen de aankoop in maart 2017 waren we met 20 gezinnen, om uiteindelijk uit te groeien tot een groep van 25 gezinnen groot. Doorheen de tijd hebben verschillende mensen onze groep nood&shy;gedwongen moeten verlaten, maar er zijn telkens ook nieuwe mensen bijgekomen. De afgelopen jaren is er sowieso hard gewerkt aan het project en is er veel bijgeleerd. In 2020 was er ook een herdoping van Gummarushof naar Gumm, een nieuw huisstijl en de start van de verbouwing.</p>
        <p class="p1">Gumm is een intergenerationeel project waar ecologie, architectuur en samenwonen centraal staan. Inter&shy;gene&shy;rationeel want we zijn zowel baby-boomers als millenials; ecologie want een nieuwe balans tussen mens en omgeving is nodig; architectuur in de vorm van respect voor de bestaande plek; en samenwonen binnen de cohousing zelf maar ook met de buurt.</p>
    </div>     
</template>

<script>  
    export default {    
            methods: {
                lFilter: function(e) {
                    this.$parent.mfilter(e);         
                    window.scrollTo(0,0); 
                }

            }
        }
</script>

<style></style>
