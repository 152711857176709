import Vue from 'vue';
import App from './App.vue';
import VueScrollTo from 'vue-scrollto';
import DropdownMenu from 'v-dropdown-menu';
import { VLazyImagePlugin } from "v-lazy-image";
import isotope from 'vueisotope';
import imagesLoaded from 'vue-images-loaded';
import 'v-dropdown-menu/dist/v-dropdown-menu.css'; 
import HelloWorld from './components/HelloWorld.vue';
import overGummIMG from './components/overGummIMG.vue';
import overGummTXT from './components/overGummTXT.vue';
import dividerA from './components/dividerA.vue';
import dividerB from './components/dividerB.vue';
import dividerC from './components/dividerC.vue';
import dividerD from './components/dividerD.vue';
import dividerE from './components/dividerE.vue';
import dividerF from './components/dividerF.vue';
import overSiteIMG1 from './components/overSiteIMG1.vue';
import overSiteTXT from './components/overSiteTXT.vue';
import overSiteIMG2 from './components/overSiteIMG2.vue';
import overBouwTXT from './components/overBouwTXT.vue';
import overBouwIMG1 from './components/overBouwIMG1.vue';
import overBouwIMG2 from './components/overBouwIMG2.vue';
import overBouwIMG3 from './components/overBouwIMG3.vue';
import overZaalIMG1 from './components/overZaalIMG1.vue';
import overZaalIMG2 from './components/overZaalIMG2.vue';
import overZaalTXT from './components/overZaalTXT.vue';
import infoNws from './components/infoNws.vue';
import infoContact from './components/infoContact.vue';
import infoIMG from './components/infoIMG.vue';
import zaalTXT from './components/zaalTXT.vue';
import zaalIMG1 from './components/zaalIMG1.vue';
import zaalIMG2 from './components/zaalIMG2.vue';
import tuinTXT1 from './components/tuinTXT1.vue';
import tuinTXT2 from './components/tuinTXT2.vue';
import tuinTXT3 from './components/tuinTXT3.vue';
import tuinTXT4 from './components/tuinTXT4.vue';
import tuinIMG1 from './components/tuinIMG1.vue';
import tuinIMG2 from './components/tuinIMG2.vue';
import tuinIMG4 from './components/tuinIMG4.vue';
import tuinIMG3 from './components/tuinIMG3.vue';
import tuinIMG5 from './components/tuinIMG5.vue';
import tuinIMG6 from './components/tuinIMG6.vue';
import tuinIMG7 from './components/tuinIMG7.vue';
import overChTXT1 from './components/overChTXT1.vue';
import overChTXT2 from './components/overChTXT2.vue';
import overChTXT3 from './components/overChTXT3.vue';
import overChTXT4 from './components/overChTXT4.vue';
import overHuisTXT from './components/overHuisTXT.vue';
import overHuisIMG1 from './components/overHuisIMG1.vue';
import overHuisIMG2 from './components/overHuisIMG2.vue';
import overHuisIMG3 from './components/overHuisIMG3.vue';
import overHuisIMG4 from './components/overHuisIMG4.vue';
import overHuisIMG5 from './components/overHuisIMG5.vue';
import overHuisIMG6 from './components/overHuisIMG6.vue';
import overHuisIMG7 from './components/overHuisIMG7.vue';
import overHuisIMG8 from './components/overHuisIMG8.vue';
import overHuisIMG9 from './components/overHuisIMG9.vue';
import overHuisIMG10 from './components/overHuisIMG10.vue';
import loginsA from './components/loginsA.vue';
import gglCalendar from './components/gglCalendar.vue';

Vue.config.productionTip = false;

Vue.use(VueScrollTo);
Vue.use(DropdownMenu) 
Vue.use(VLazyImagePlugin);


Vue.component('HelloWorld', HelloWorld);
Vue.component('overGummIMG', overGummIMG);
Vue.component('overGummTXT', overGummTXT);
Vue.component('dividerA', dividerA);
Vue.component('dividerB', dividerB);
Vue.component('dividerC', dividerC);
Vue.component('dividerD', dividerD);
Vue.component('dividerE', dividerE);
Vue.component('dividerF', dividerF);
Vue.component('overSiteIMG1', overSiteIMG1);
Vue.component('overSiteTXT', overSiteTXT);
Vue.component('overSiteIMG2', overSiteIMG2);
Vue.component('overBouwTXT', overBouwTXT);
Vue.component('overBouwIMG1', overBouwIMG1);
Vue.component('overBouwIMG2', overBouwIMG2);
Vue.component('overBouwIMG3', overBouwIMG3);
Vue.component('overZaalIMG1', overZaalIMG1);
Vue.component('overZaalIMG2', overZaalIMG2);
Vue.component('overZaalTXT', overZaalTXT);
Vue.component('infoNws', infoNws);
Vue.component('infoContact', infoContact);
Vue.component('infoIMG', infoIMG);
Vue.component('zaalTXT', zaalTXT);
Vue.component('zaalIMG1', zaalIMG1);
Vue.component('zaalIMG2', zaalIMG2);
Vue.component('tuinTXT1', tuinTXT1);
Vue.component('tuinTXT2', tuinTXT2);
Vue.component('tuinTXT3', tuinTXT3);
Vue.component('tuinTXT4', tuinTXT4);
Vue.component('tuinIMG1', tuinIMG1);
Vue.component('tuinIMG2', tuinIMG2);
Vue.component('tuinIMG4', tuinIMG4);
Vue.component('tuinIMG3', tuinIMG3);
Vue.component('tuinIMG5', tuinIMG5);
Vue.component('tuinIMG6', tuinIMG6);
Vue.component('tuinIMG7', tuinIMG7);
Vue.component('overChTXT1', overChTXT1);
Vue.component('overChTXT2', overChTXT2);
Vue.component('overChTXT3', overChTXT3);
Vue.component('overChTXT4', overChTXT4);
Vue.component('overHuisTXT', overHuisTXT);
Vue.component('loginsA', loginsA);
Vue.component('overHuisIMG1', overHuisIMG1);
Vue.component('overHuisIMG2', overHuisIMG2);
Vue.component('overHuisIMG3', overHuisIMG3);
Vue.component('overHuisIMG4', overHuisIMG4);
Vue.component('overHuisIMG5', overHuisIMG5);
Vue.component('overHuisIMG6', overHuisIMG6);
Vue.component('overHuisIMG7', overHuisIMG7);
Vue.component('overHuisIMG8', overHuisIMG8);
Vue.component('overHuisIMG9', overHuisIMG9);
Vue.component('overHuisIMG10', overHuisIMG10);
Vue.component('gglCalendar', gglCalendar);


var app = new Vue({
    render: h => h(App),
}).$mount('#app')
