<template>
    <div class="contact">    
        <p class="title">contact</p>
        <p class="p1"  lang="nl">
            vzw Gumm<br>
            Kerkhoflei 49<br>
            2800 Mechelen <br>
            <a href ="mailto:info@gumm-cohousing.be" target="_blank" rel="noopener noreferrer">info@gumm-cohousing.be</a> <br>
        </p>    
        <div class="socials">
            <div class="social"><a href="https://www.instagram.com/gumm.cohousing" target="_blank" rel="noopener noreferrer"><img src="images/logos/insta.png" style="width:20px;height:20px;border:0;"></a></div>
            <div class="social"><a href="https://www.facebook.com/gumm.cohousing" target="_blank" rel="noopener noreferrer"><img src="images/logos/fb.png" style="width:19px;height:19px;border:0;"></a></div>
        </div> 
    </div>    
</template>

<script>
export default {

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    
    .contact{
        width: 100%;    
    }
    
    .socials{
        margin-top:20px;
        width: 10vw;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .social{
        margin-right: 10px;
        cursor: pointer;
    }
    .social:hover{
        opacity: 0.7;
    }
</style>