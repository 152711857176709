<template>
    <div>
        <div class="imgGummieLink">    
            <a href="https://yoganiketan.be" target="_blank" rel="noopener noreferrer"><v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/over/huis/gummXaveerNadine.png" src-placeholder="images/over/huis/placeHolderHuis.png"/></a>
        </div>         
        <p class="txtGummie"  style="margin-top: -3vh;padding-left: 5vw; padding-right: 2vw;"><em>Het concept cohousing spreekt ons aan door het gemeen&shy;schappelijk wonen, maar toch met voldoende privé. We zijn tot nu toe het meest verrast doordat samen beslissen véél tijd en aandacht vraagt: we zijn samen leven met anderen niet meer gewend. We kijken ernaar uit om meer en vooral een goed contact te hebben met onze buren. Yoga, reizen en techniek zijn passies waar we ons in kunnen verliezen en die we graag willen delen met de andere gummies. </em> --Xaveer & Nadine</p>
    </div>     
</template>

<script>
export default {
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

    
    @media only screen and (min-width: 0px) {
     

    }    
    
    @media only screen and (min-width: 1824px) {

    }    
    
</style>