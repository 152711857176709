<template>
    <div>
        <p class="title">biodiversiteit</p>
        <p class='p1'>Ook voor dieren wordt er in de gummtuin ruimte gemaakt. In de winter van 2020 bouwden we in samenwerking met <a href="https://www.natuurpunt.be/ " target="_blank" rel="noopener noreferrer">Natuurpunt</a> een nestkast voor kerkuilen en hopen er snel een nieuwe bewoner te mogen ontvangen. In de toekomst worden nog zwaluwnesten opgehangen aan de gummzaal en een vleermuizenverblijf gebouwd op de kerkzolder. Een doordachte keuze van beplanting en tuinontwerp trekken ook andere bewoners zoals vogels, kleine zoogdieren en insecten aan.
        Daarnaast houdt gummie Nils een inventaris bij van interessante soorten die we in de tuin kunnen vinden.</p>
    </div>    
</template>

<script>
export default {
  name: 'tuinTXT'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>   
    
    
    @media only screen and (min-width: 0px) {

    

    }    
    
    @media only screen and (min-width: 1536px) {


        
    }    
    
</style>
