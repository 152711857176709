<template>
    <div>
        <div class="imgGummieLink">    
            <a href="https://www.denekker.be/de-nekkerpool.html" target="_blank" rel="noopener noreferrer"><v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/over/huis/gummPaule.png" src-placeholder="images/over/huis/placeHolderHuis.png"/></a>
        </div>         
        <p class="txtGummie"  style="margin-top: 1vh;padding-left:6vw; padding-right: 2vw;"><em>Ik word blij van kinderen die spelen, muziekmakende en zingende volwassenen, de natuur, een mooi gebouw - bijvoorbeeld het kerkgebouw - een leuke wandeling of fietstocht, een half uurtje zwemmen of in het water spelen en ‘fikfakken’ met de kleinkinderen, ... . Ik droom ervan tot mijn honderdste te blijven deel uitmaken van de gummies.</em> --Paule</p>
    </div>     
</template>

<script>
export default {
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

    
    @media only screen and (min-width: 0px) {
     

    }    
    
    @media only screen and (min-width: 1824px) {

    }    
    
</style>