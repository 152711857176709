<template>
        <div>
            <p class="title">activiteiten</p>      
            <p class='p1'>
                In de gummtuin organiseren we activiteiten die het buurtleven en ontmoeting versterken. 
                <ul>
                    <li><div>Vlindervinders en Mottenzotten: <p>In 2019 organiseerden Gumm cohousing en <a href="https://www.natuurpunt.be/afdelingen/natuurpunt-mechels-rivierengebied/natuurpunt-mechelen" target="_blank" rel="noopener noreferrer">Natuurpunt Mechelen</a> samen de eerste editie van 'Vlindervinders en Mottenzotten'. Twintig kinderen bleven kamperen en gaven zich over aan een gevuld programma vol natuureducatieve spelletjes, pannenkoeken, vlinderpasta, nachtvlindervallen, boomsmeren en vooral veel mooie motten! In 2022 kunnen we deze activiteit weer laten doorgaan in onze gummtuin! Een reportage van de editie in 2019 kan je <a href="https://www.rtv.be/artikels/vlindervinders-en-mottenzotten-a70728" target="_blank" rel="noopener noreferrer">hier</a> bekijken.</p></div></li>
                    <li><div>Samen bouwen aan een kindvriendelijke gedeelde tuin: <p>In de zomer van 2018 gingen we samen met <a href="https://k-s.be/" target="_blank" rel="noopener noreferrer">Kind & Samenleving</a>, <a href="https://www.speelplein.net/" target="_blank" rel="noopener noreferrer">Vlaamse Dienst Speelpleinwerk</a> en de <a href="https://speelmakers.be/" target="_blank" rel="noopener noreferrer">Speelmakers</a> aan de slag om onze pastorietuin een volledige make‐over te geven in het kader van het project 'Kinderen & Voortuinen'. De tuin werd met de buurtkinderen speelvriendelijker gemaakt, zodat de  drempel voor kinderen om buiten te spelen wordt verlaagd. We experimenteerden met een avontuurlijke aanleg van de collectieve tuin, o.a. een boomhut van paletten, een tipidorp en speelheuvel kwamen te voorschijn. Meer informatie vind je in deze <a href="https://k-s.be/medialibrary/purl/nl/7570163/Kinderen%20&%20voortuinen.pdf" target="_blank" rel="noopener noreferrer">documentatie</a>.</p></div></li>             
                </ul>
            </p>            
    </div>    
</template>

<script>
export default {
  name: 'tuinTXT'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>   
    
    
    @media only screen and (min-width: 0px) {


    }    
    
    @media only screen and (min-width: 1536px) {

        
    }    
    
</style>
