<template>
    <div>
        <div class="imgGummieLink">    
            <a href="https://vgtleren.be" target="_blank" rel="noopener noreferrer"><v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/over/huis/gummJokeC.png" src-placeholder="images/over/huis/placeHolderHuis.png"/></a>
        </div>         
        <p class="txtGummie"  style="margin-top: 1vh;padding-left: 3vw; padding-right: 3.5vw;"><em>Voor mij is cohousing verbondenheid en gezelligheid, kortom niet alle dagen alleen zijn. Het is een avontuur net zoals de films, series en strips waarin ik me helemaal kan verliezen: een science fiction en fantasy geek dus. In mijn jonge jaren was ik een Trekkie. Organiseren is mijn talent: mensen bij elkaar brengen, laten lachen en plezier maken. </em> --Joke</p>
    </div>     
</template>

<script>
export default {
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

    
    @media only screen and (min-width: 0px) {
     

    }    
    
    @media only screen and (min-width: 1824px) {

    }    
    
</style>