<template>
    <div class="overSiteTXT">
        <p class="title">over de site</p>
        <p class='p1' lang="nl">De Gumm site is gelegen op de Kerkhoflei, op de grens van Pennepoel en Nekkerspoel in Mechelen, ook wel beter bekend als de Capusteen&shy;buurt. Een rustige omgeving vlakbij het stadscentrum, de fietsostrade naar Antwerpen-Brussel en openbaar groen: het Caputsteen&shy;park en het Tivolipark liggen op een boogscheut. Ook natuur is vlakbij, natuur&shy;gebied Kauwendaal ligt op fietsafstand. De Vrouwvliet, die even verder uitmondt in de Dijle, is de blauwe ader van de buurt en een bron van bio&shy;diversiteit. </p>
        <p class='p1' lang="nl">De plek is een voormalig schoolcomplex, toen bekend als Sint-Gummarus, met een kerk buiten dienst, hoekhuis en pastorie. In 1925 werd het bouwplan voor het hoekhuis en de meisjesschool goed&shy;gekeurd, getekend door architecten Simon van Craen en Jef Huygh. Deze laatste gaf een eigen interpretatie aan de art deco wat in dit ontwerp onder andere resulteerde in de keperboog&shy;vormige inkom en nissen en de buikige deelzuiltjes aan de bovenvensters.</p>
        <p class='p1' lang="nl">De meisjesschool werd al in 1926 in gebruik genomen en begin jaren 30 uitgebreid. Het hoekhuis was er ook al bij in 1926 en heeft dienst gedaan als woning van de zusters, als pastorie en later ook als lokalen voor de scouts. Binnenkort wordt dit het <span class="linkInText" @click="lFilter(5)">gummhuis</span> met gemeen&shy;schappelijke ruimtes voor de bewoners: een polyvalente ruimte, een keuken, een leeskamer, een hobbyruimte, logeerkamers, ... . De gebouwen van de jongensschool werden pas toegevoegd eind jaren 50. De laatste scholieren volgden hier tot in de jaren 2000 nog les. De meisjes- en jongensschool zijn nu omgevormd tot 23 woon&shy;eenheden. In de jongensschool komt er ook een gedeelde wasbar. Naast de pastorie, die zelf nog een wooneenheid vormt, wordt er een atelier voorzien. Aan de overzijde van de servitudeweg wordt het bestaande huis ook omgezet in een woonunit, deel uitmakend van het geheel. De servitudeweg geeft toegang tot de Gumm site en de huidige lokalen van de <a href="https://gummarusolv.be/" target="_blank" rel="noopener noreferrer">Scouts Sint-Gummarus en Gidsen OLV.</a></p>        
    </div>    
</template>

<script>
export default {

        methods: {
            lFilter: function(e) {
                this.$parent.mfilter(e);
                window.scrollTo(0,0); 
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
     @media only screen and (min-width: 0px) {
        
        .overSiteTXT{
        }       
        
    }
    
    @media only screen and (min-width: 1024px) {

        .overSiteTXT{
            margin-bottom: 7.5vh;
        }

    } 
      

    @media only screen and (min-width: 1824px) {

        .overSiteTXT{
            margin-bottom: 7.5vh;
        }

    }  
</style>