<template>
    <div class="overHuisIMG2">
        <div class="imgGummieLink">    
            <a href="https://www.instagram.com/krisje77/" target="_blank" rel="noopener noreferrer"><v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/over/huis/gummKrisje.png" src-placeholder="images/over/huis/placeHolderHuis.png"/></a>
        </div>     
        <p class='txtGummie' style="margin-top: -0.5vh; padding-left: 6vw;"><em>Muziek laat me huilen,<br>
            laat me lachen <br>
            Muziek is liefde en veel meer. </em></p>
        <p class="txtGummie" style="padding-left: 7vw; padding-right: 2vw;"><em>Muziek doet alles, het benadrukt soms je gevoel maar het kan je uit een gevoel halen en je overweldigen door een volledige andere emotie. Ik geniet enorm van muziek, en vooral op een grasplein, met vrienden en een pintje erbij. Loeiharde gitaren, headbangen, beklijvende muziek, kleinkunst en folk krijgen toch mijn voorkeuren. Ik speel geen enkel instrument, geen muziektalent in mij, maar misschien met heel veel gummiesgeduld komt het er ooit nog van.</em> --Kris</p>
    </div>     
</template>

<script>
export default {
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

    
    @media only screen and (min-width: 0px) {
     

    }    
    
    @media only screen and (min-width: 1824px) {
        .overHuisIMG2{
            padding-bottom: 1vh;
        }
    }    
    
</style>
