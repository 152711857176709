<template>
    <div class="overBouwIMG3">    
        <v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/over/bouw/werf0205062020.png" src-placeholder="images/over/bouw/placeholderWerf0205062020.png" />        
    </div>    
</template>

<script>  
    export default {    
            methods: {
                lFilter: function(e) {
                    this.$parent.mfilter(e);         
                    window.scrollTo(0,0); 
                }

            }
        }
</script>

<style>

    @media only screen and (min-width: 0px) {
        
        .overBouwIMG3{
            padding-top: 0vh;
            padding-left: 4vw;
            padding-right: 4vw;
            padding-bottom: 0vh;            
        }        
        
    }
    
    @media only screen and (min-width: 1024px) {

        .overBouwIMG3{
            padding-top: 1vh;
            padding-left: 14vw;
            padding-right: 2vw;
            padding-bottom: 1vh;     
            margin-bottom: 5vh;
        }      

    }    
    
    @media only screen and (min-width: 1824px) {

        .overBouwIMG3{
            padding-top: 2vh;
            padding-left: 4vw;
            padding-right: 4vw;
            padding-bottom: 2vh;    
            margin-bottom: 5vh;
        }

    }         
    
    
</style>