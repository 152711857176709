<template>
    <div class="wCaption tuinIMG6">
        <agile :options="AgileOptions" :initialSlide=0 @after-change="e => currentSlide = e.currentSlide">
            <template slot="caption">{{ captions[currentSlide] }}</template>
            <div class="slide"><v-lazy-image  style="padding-left: 0px; padding-right: 0px;" src="images/tuin/tuinGUMMbraammineermot-01.jpg" src-placeholder="images/tuin/placeholderDierPortrait.png"/></div>
            <div class="slide"><v-lazy-image  style="padding-left: 0px; padding-right: 0px;" src="images/tuin/tuinGUMMtammekastanjegalwesp-02.jpg" src-placeholder="images/tuin/placeholderDierPortrait.png"/></div>            
            <div class="slide"><v-lazy-image  style="padding-left: 0px; padding-right: 0px;" src="images/tuin/tuinGUMMbeukenbladsnijdermot-01.jpg" src-placeholder="images/tuin/placeholderDierPortrait.png"/></div>            
            <div class="slide"><v-lazy-image  style="padding-left: 0px; padding-right: 0px;" src="images/tuin/tuinGUMMstinkedegouwe-01.jpg" src-placeholder="images/tuin/placeholderDierPortrait.png"/></div>
            <template slot="prevButton">←</template>
            <template slot="nextButton">→</template>        
        </agile>        
        <div class="solo"><v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/tuin/tuinGUMMbraammineermot-01.jpg" src-placeholder="images/tuin/placeholderDierPortrait.png"/><figcaption>Rechts in beeld zie je een prachtige witte mijn starten van een Braammineermot of Bosbramenmineermot, die de bladrand volgt en alsmaar breder wordt naargelang het mijnspoor vordert. Dit microvlindertje legde zijn eitje aan de onderkant van het deelblaadje van het braamblad. Het rupsje dat uit het eitje kwam deed zichzelf te goed aan het bladmoes van het blad tot het volgroeid was en het het blaadje verliet om te verpoppen.
        </figcaption></div>   
    </div>     
</template>

<script>  
    import { VueAgile } from 'vue-agile'    
    export default {    
            data: function () {
                "use strict";
                return {
                    AgileOptions: {
                                slidesToShow: 1,
                                navButtons: false,         
                                responsive: [
                                    {
                                        breakpoint: 768,
                                        settings: {
                                            navButtons: true,
                                        }
                                    }
                                ]                    
                      },
                    currentSlide: 0,
                    captions: [
                        'Rechts in beeld zie je een prachtige witte mijn starten van een Braammineermot of Bosbramenmineermot, die de bladrand volgt en alsmaar breder wordt naargelang het mijnspoor vordert. Dit micro-vlindertje legde zijn eitje aan de onderkant van het deelblaadje van het braamblad. Het rupsje dat uit het eitje kwam deed zichzelf te goed aan het bladmoes van het blad tot het volgroeid was en het het blaadje verliet om te verpoppen.',
                        'De Tamme-kastanjegalwesp komt oorspronkelijk voor in China en is zo’n tiental jaren geleden door de import van aangetaste planten in Europa terecht gekomen. Wetenschappers onderzochten het DNA van de galwespen en vonden dat alle populaties die in Europa voorkomen afkomstig zijn van slechts enkele vrouwtjes. De tamme-kastanjegalwesp kan zich ongeslachtelijk voortplanten: er zijn geen mannetjes nodig.',
                        'Deze zeer zeldzame microvlinder is de beukenbladsnijdermot, een klein geluk dat het op Gumm is gespot. Dit vlindertje heeft beuk, eik en linde als waardplanten. De jonge rupsen mineren: ze knagen een rondje uit de boven- en onderlaag van het blad. De oudere rups laat zich op de grond vallen en eet van dood blad waarna hij er verpopt in een van blad gemaakt zakje.',                        
                        'De doosvrucht van de Stinkende Gouwe bevat zaden die voorzien zijn van een mierenbroodje (een vettige witte zaadsluier) waar mieren verzot op zijn. De mieren staan in voor de verspreiding van de plant, doordat ze de zaden met het mierenbroodje meenemen naar hun nest, waar de oneetbare zaden ontkiemen. Daarom vind je deze planten vaak terug op mierensnelwegen; de groeven tussen stenen en tegels.'

                    ]
                };          
            },
            components: {
                'agile': VueAgile,
              },         
            methods: {
                lFilter: function(e) {
                    this.$parent.mfilter(e);         
                    window.scrollTo(0,0); 
                }

            }
        }
</script>

<style>

    @media only screen and (min-width: 0px) {

        .tuinIMG6 {
            margin-top: 0vh;
            margin-right: 12vw;
            margin-left: 5vw;
            margin-bottom: 2.5vh;            

        }     

    }
    
    @media only screen and (min-width: 1024px) {

        .tuinIMG6 {
            margin-top: -1vh;
            margin-right: 0vw;
            margin-left: 5vw;
            margin-bottom: 15vh;            

        }
    }
      

    @media only screen and (min-width: 1824px) {

        .tuinIMG6 {
            margin-top: -2.5vh;
            margin-right: -10vw;
            margin-left: 5vw;
            margin-bottom: 15vh;
        }
    }

    .tuinIMG6 .agile__caption{
        text-align: left;
        padding-left: 5%;
        padding-right: 5%;
        padding-top: 1vh;        
    }
    .tuinIMG6 .agile__dots {
        bottom: 1.5vh;
    }
    
    .tuinIMG6 .agile__nav-button {    
        bottom: 1.1vh;
    }
    .tuinIMG6 figcaption{
        text-align: left;
    }    

</style>