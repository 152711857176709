<template>
    <div class="overBouwIMG2">    
            <v-lazy-image style="padding-left: 0px; padding-right: 0px;" src="images/over/bouw/gummHoekRenderDenc.jpg" src-placeholder="images/over/bouw/placeholderHoekRenderDenc.png" />
    </div>    
</template>

<script>  
    export default {    

            methods: {
                lFilter: function(e) {
                    this.$parent.mfilter(e);         
                    window.scrollTo(0,0); 
                }

            }
        }
</script>

<style>

   @media only screen and (max-width: 0px) {
        
        .overBouwIMG2{
            padding-top: 0vh;
            padding-left: 3vw;
            padding-right: 4vw;
            padding-bottom: 0vh;            
        }        
        
    }
    
    @media only screen and (min-width: 1024px) {

        .overBouwIMG2{
            padding-top: 2.5vh;
            padding-left: 1vw;
            padding-right: 1vw;
            padding-bottom: 1vh;            
        }      

    }    
    
    @media only screen and (min-width: 1824px) {

        .overBouwIMG2{
            padding-top: 1vh;
            padding-left: 2vw;
            padding-right: 2vw;
            padding-bottom: 1vh;            
            margin-right: 0vw;
        }

    }      
    
</style>