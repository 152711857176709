<template>
    <div class="tuinIMG2">    
        <v-lazy-image style="padding-left: 1vw; padding-right: 1vw;" src="images/tuin/tuinGUMM052020lrPeilbuis-05.jpg" src-placeholder="images/tuin/placeholderPeilbuis.png"/>
    </div>    
</template>

<script>  
    export default {    
            methods: {
                lFilter: function(e) {
                    this.$parent.mfilter(e);         
                    window.scrollTo(0,0); 
                }

            }
        }
</script>

<style>


    @media only screen and (min-width: 0px) {
        
        .tuinIMG2 {
            margin-top: -2.5vh;
            margin-right: 8vw;
            margin-left: 17vw;
            margin-bottom: 2.5vh;

        }
    }
    
    @media only screen and (min-width: 1024px) {

        .tuinIMG2 {
            margin-top:-0.5vh;
            margin-right: 2vw;
            margin-left: 2vw;
            margin-bottom: 5vh;            

        }
    }
      

    @media only screen and (min-width: 1824px) {

        .tuinIMG2 {
            margin-top: -2.5vh;          
            margin-right: 0vw;
            margin-left: 0vw;              
        }

    }      
</style>