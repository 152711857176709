<template>
    <div>
        <p class="title">ontharden</p>
        <p class='p1'>In de nazomer van 2020 lieten we studiebureau <a href=" https://www.witteveenbos.com/nl/belgie/" target="_blank" rel="noopener noreferrer">Witteveen+Bos</a> los op onze tuin om een water&shy;infiltratieplan voor het ganse terrein te maken. Als eerste stap werden in de zomer de grond&shy;waterstand en de water&shy;doorlaatbaarheid van de bodem gemeten met peilbuizen. Door jaren&shy;lang afgedekt te zijn geweest met een laag beton of kassei&shy;stenen kan de bodem soms zodanig samengedrukt worden dat zij ondoor&shy;dringbaar wordt voor regenwater. We hebben geluk, de sponsfunctie zit op het eerste zicht goed en met een doordacht plan met wadi’s en land&shy;schappelijke glooiingen kunnen we ze zelfs nog beter maken.</p><p class='p1'>De diepte van het grondwater is zoals overal in Vlaanderen niet fantastisch, maar is met 2 meter diepte in de late zomer toch beter dan verwacht in vergelijking met enkele andere metingen in de streek. Dankzij drie wadi’s die samen bijna 18m<sup>3</sup> water kunnen opvangen en lokaal laten infiltreren, zorgen we ervoor dat de situatie nog verbetert. Verder onderzochten Witteveen+Bos hoeveel regenwater we best opzij houden voor recuperatie via regenwaterputten. Op basis van de resultaten van deze studie hebben we besloten om 3 waterputten, goed voor 60.000l regenwater, te voorzien. Het regenwater her&shy;gebruiken we in onze tuin, onze gemeen&shy;schap&shy;pelijke toiletten en wasruimte.</p>
        <p class='p1'>Ter hoogte van de units werd al meer dan 200m<sup>2</sup> verharding uitgebroken. Op deze plaats komen terrassen die voor 55% uit waterpasserende tegels bestaan. Hierdoor zal al het water, dat op de terrassen valt, lokaal kunnen infiltreren. Vanaf het voorjaar 2023 plannen we rond&shy;leidingen en werkdagen in onze tuin. Hou dus zeker onze <a href="https://www.facebook.com/gumm.cohousing" target="_blank" rel="noopener noreferrer">facebook</a>, <a href="https://www.instagram.com/gumm.cohousing" target="_blank" rel="noopener noreferrer">instagram</a> en <a href='https://mailchi.mp/5e1ae67217eb/nieuwsbrief-gumm-cohousing' target="_blank" rel="noopener noreferrer">nieuwsbrief</a> in de gaten.</p>       
    </div>     
</template>

<script>
export default {
  name: 'tuinTXT'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>   
    
    
    @media only screen and (min-width: 0px) {

        .tuinIMG1{
     
        }      

    }    
    
    @media only screen and (min-width: 1536px) {

        .tuinIMG3 {
            margin-top: 0vh;
            margin-right: 0vw;
            margin-left: 0vw;
            margin-bottom: 0vh;
        } 
        
    }    
    
</style>
