<template>
    <div>
        <p class="title">over de verbouwing</p>
        <p class='p1' lang="nl">De 23 woon&shy;eenheden in de oude schoolgebouwen zijn Bijna Energie Neutraal (BEN) verbouwd. Een BEN-woning verbruikt weinig energie voor verwarming, ventilatie, koeling en warm water. En de energie die nodig is, wordt uit groene energiebronnen gehaald. Om dit te verwezenlijken hebben we gekozen voor geo&shy;thermische warmtepompen, zonnepanelen en vermijden we oververhitting door de plaatsing van zonnewering op de ramen en voorzetstructuur. In de woon&shy;eenheden kan er dankzij de warmtepompen ook nog passief gekoeld worden. Naast de groene <span class= "linkInText" @click="lFilter(9)">gummtuin</span> zorgt de aanwezigheid van groendaken en gevels ook voor een afkoelend effect.</p>
        <p class='p1' lang="nl">De architectuur in het project is verzorgd door <a href="https://www.denc-studio.be/projecten/gummarushof-cohousing" target="_blank" rel="noopener noreferrer">DENC!-STUDIO</a>. Een kernwoord in het project is reconversie: de toekomst van de stad en wonen wordt bepaald door wat er al is, en gumm cohousing is daar dus een voorbeeld van. Om 23 wooneenheden te creeëren, is de bestaande structuur uitgebreid met houtskeletbouw. De woon&shy;eenheden in de bestaande jongensschool komen overeen met de oude klaslokalen en worden uitgebreid tot gezins&shy;woningen met een optopping, de oude gang wordt omgevormd naar de wasbar.</p> 
        <p class='p1' lang="nl">Ook in de voormalige meisjesschool zorgen de klaslokalen voor de indeling van woon&shy;eenheden, op de oude feest- en turnzaal daarin na: die is opgedeeld in vier eenheden. De hout&shy;skelet&shy;bouw op de meisjesschool zorgt voor 7 eenheden in 2 bouwlagen, waarvan 1 een verderzetting is van de bestaande 1ste verdieping. Om de oppervlakte van de bestaande structuur zoveel mogelijk te maximaliseren wordt de toegang en verdeling van de woon&shy;eenheden verzorgd door een voorzetstructuur die daarnaast ook andere functies kan vervullen (o.a. zonnewering, groengevel, ...). Om beide school&shy;gebouwen terug te verbinden is ook de jongensschool voorzien van een voorzetstructuur.</p>  
        <p class='p1' lang="nl">In de verbouwing is er zoveel mogelijk naar gestreefd om het bestaande karakter te behouden. Historische details zijn zoveel mogelijk bewaard en opnieuw toegepast. Storende elementen zijn verwijderd en beschadigingen hersteld. Die laatste laten we als zichtbare littekens en getuigen van het verleden. Op die manier krijgt de basisarchitectuur uit de jaren 20 zijn kracht terug en blijft de geschiedenis van de gebouwen zichtbaar.</p>   
        <p class='p1' lang="nl">De werf loopt naar het einde toe. Maar er is nog werk in onze gezamenlijke delen en de gummzaal. Hoe dat verloopt kan je nog steeds volgen op onze <a href="https://www.instagram.com/gumm.cohousing" target="_blank" rel="noopener noreferrer">instagram</a> en <a href="https://www.facebook.com/gumm.cohousing" target="_blank" rel="noopener noreferrer">facebook</a> pagina. De verbouwing wordt uitgevoerd door  <a href="https://g-build.be/" target="_blank" rel="noopener noreferrer">G-build</a> als hoofdaanenemer en <a href="http://www.pxp.be/" target="_blank" rel="noopener noreferrer">PxP</a> als nevenaannemer voor de houtskeletbouw. De woning naast de pastorij en de pastorij zelf worden op hun eigen tempo verbouwd, maar maken wel deel uit van het geheel.</p>    
    </div>     
</template>

<script>  
    export default {    
            methods: {
                lFilter: function(e) {
                    this.$parent.mfilter(e);         
                    window.scrollTo(0,0); 
                }

            }
        }
</script>

<style></style>
